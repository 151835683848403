import { ReactComponent as Rotate } from "../assets/icons/Rotate.svg";

const RotateWarn = () => (
  <div className="fixed top-0 bottom-0 left-0 right-0 z-50 h-screen w-screen hidden lg:flex xs:landscape:flex flex-col items-center justify-center bg-primary text-white">
    <Rotate className="shrink-0 h-32 w-36" />
    <p className="mt-6 w-72 text-lg text-center">
      Please rotate your phone to access the application.
    </p>
  </div>
);

export default RotateWarn;
