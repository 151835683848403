import { Link, useLocation } from "react-router-dom";

const VideoConsent = () => {
  const { state } = useLocation();
  return (
    <section className="px-6 py-8">
      <h3 className="text-secondary text-2xl font-bold">
        Record your
        <br />
        consent video
      </h3>
      <p className="mt-4 text-white text-sm text-justify">
        Please carefully read the text provided and record a video where you
        express your consent to the terms or conditions outlined in the text.
        Your consent video serves as acknowledgment that you have thoroughly
        reviewed and agree to the specified terms. This video ensures
        transparency and compliance with the agreed-upon conditions, and it may
        be used for verification or documentation purposes as needed. Your
        cooperation in recording this consent video is greatly appreciated.
      </p>
      <div className="fixed bottom-0 left-0 right-0 flex flex-col items-center justify-center px-6 pt-8 pb-5 rounded-t-2xl bg-[#051645]/50">
        <Link
          to="/pivc/video-consent-record"
          state={state}
          className="w-full rounded-full outline-none px-5 py-2.5 bg-gradient text-white text-center"
        >
          Get Started
        </Link>
        <Link to={-1} className="mt-4 text-sm font-medium">
          Cancel
        </Link>
      </div>
    </section>
  );
};

export default VideoConsent;
