import { useSearchParams } from "react-router-dom";
import { ReactComponent as GlowingHeart } from "../../assets/icons/GlowingHeart.svg";

const WatchVideoConsent = () => {
  const [searchParams] = useSearchParams();
  const videoURL = searchParams.get("video_url") ?? "";

  return (
    <section className="px-6 pt-8 pb-40 relative">
      <h1 className="text-xl text-white font-semibold">
        CarePlix | PIVC | Video Consent
      </h1>
      <div className="relative mt-8">
        <GlowingHeart className="absolute -top-[3.25rem] -right-6 h-24 w-24 z-50" />
        <video
          className="w-full rounded-2xl"
          src={videoURL}
          playsInline
          controls
          controlsList="nodownload nofullscreen noremoteplayback noplaybackrate"
          disablePictureInPicture
          disableRemotePlayback
        />
      </div>
      <button
        type="button"
        onClick={() => {
          window.close();
        }}
        className="mt-16 w-full rounded-full outline-none px-5 py-2.5 bg-gradient disabled:bg-none disabled:bg-[#9EAAC7] text-white"
      >
        Close this Tab
      </button>
    </section>
  );
};

export default WatchVideoConsent;
