import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useAuth } from "../../utils/auth";
import { sdkWrapperURL } from "../../utils/api-url-list";

const IssuanceDetails = () => {
  const { getToken, userData } = useAuth();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const apply = async () => {
    try {
      setLoading(true);
      const token = await getToken();
      const resp = await fetch(sdkWrapperURL("/pivc/v1/check-status"), {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
        body: JSON.stringify({}),
      });
      const respJSON = await resp.json();
      if (respJSON?.statusCode?.toString().startsWith("2")) {
        const status = respJSON.pivc_status?.toUpperCase?.();
        switch (status) {
          case "PROOF_VALIDATED":
            navigate("/pivc/status", {
              replace: true,
              state: {
                title: "Upload Successful",
                subtitile: "Proceed to the next step of verification",
                next: {
                  to: "/start-scan",
                  state: { pivc: true, pivcID: respJSON.pivc_id },
                },
              },
            });
            break;
          case "FACE_SCAN_IMAGE_UPLOADED":
          case "RECORDING_UPLOAD_INITIATED":
          case "STAGE_3_ERROR":
            navigate("/pivc/status", {
              replace: true,
              state: {
                title: "Face Scan Completed",
                subtitile: "Proceed to the last step of verification",
                next: {
                  to: "/pivc/video-consent",
                  state: { pivcID: respJSON.pivc_id },
                },
              },
            });
            break;
          case "RECORDING_UPLOADED":
          case "SUCCESS":
            navigate("/pivc/status", {
              replace: true,
              state: {
                title: "Congratulations!",
                subtitile:
                  "You have successfully completed the verification process.",
                next: {
                  to: "/pivc/result",
                  state: { pivcID: respJSON.pivc_id, result: respJSON.body },
                },
              },
            });
            break;
          case "STAGE_1_ERROR":
            navigate("/pivc/upload-documents");
            break;
          default:
            navigate("/pivc/upload-documents");
            break;
        }
      } else throw new Error(respJSON.message);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <section className="px-6 pt-2 pb-40">
      <h3 className="text-xl font-bold text-white">
        Pre-Issuance
        <br />
        Verification
      </h3>
      <div className="mt-8 grid grid-cols-2 gap-5">
        {[
          { label: "First Name", value: userData.fname },
          { label: "Last Name", value: userData.lname },
          {
            label: "Gender",
            value: `${userData.gender
              .charAt(0)
              .toUpperCase()}${userData.gender.slice(1)}`,
          },
          {
            label: "Date of Birth",
            value: new Date(userData.dob).toLocaleDateString(undefined, {
              day: "2-digit",
              month: "2-digit",
              year: "numeric",
            }),
          },
          { label: "Email", value: userData.email, full: true },
          {
            label: "Policy Application No.",
            value: userData.pivcInfo?.policy_application_no?.toString?.(),
          },
          {
            label: "Policy Application Date",
            value: new Date(
              userData.pivcInfo?.policy_application_date
            ).toLocaleDateString(undefined, {
              day: "2-digit",
              month: "2-digit",
              year: "numeric",
            }),
          },
          {
            label: "Premium Amount",
            value: userData.pivcInfo?.premium_amount?.toString?.(),
          },
          {
            label: "Pre-existing Disease",
            value: userData.pivcInfo?.pre_existing_disease,
          },
          {
            label: "Agent Name",
            value: userData.pivcInfo?.agent_name,
          },
          {
            label: "Policy Details",
            value: userData.pivcInfo?.policy_details,
            full: true,
          },
        ]
          .filter(({ value }) => value?.length > 0)
          .map((item, idx) => (
            <div key={idx} className={`${item.full ? "col-span-full" : ""}`}>
              <p className="text-xs text-secondary font-bold">{item.label}</p>
              <p className="mt-1 text-sm text-white">{item.value}</p>
            </div>
          ))}
      </div>
      <div className="fixed bottom-0 left-0 right-0 flex flex-col items-center justify-center px-6 pt-8 pb-5 rounded-t-2xl bg-[#051645]/50">
        <button
          type="button"
          onClick={apply}
          disabled={loading}
          className="w-full rounded-full outline-none px-5 py-2.5 bg-gradient text-white disabled:bg-none disabled:bg-[#9EAAC7]"
        >
          I Agree
        </button>
        <Link to={-1} className="mt-4 text-sm font-medium">
          Decline
        </Link>
      </div>
    </section>
  );
};

export default IssuanceDetails;
