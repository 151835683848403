import { createContext, useContext, useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { sdkWrapperURL } from "./api-url-list";
import LoadingScreen from "../components/LoadingScreen";

const decodeToken = (token = "") => {
  if (!(token?.length > 0)) throw new Error("Incorrect Token");
  const jwtPayload = token.split(".")[1];
  const base64string =
    jwtPayload.replace("-", "+").replace("_", "/") +
    "=".repeat((4 - (jwtPayload.length % 4)) % 4);
  const TABLE =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/";
  const REGEX_SPACE_CHARACTERS = /<%= spaceCharacters %>/g;
  let input = String(base64string).replace(REGEX_SPACE_CHARACTERS, "");
  let length = input.length;
  if (length % 4 === 0) {
    input = input.replace(/==?$/, "");
    length = input.length;
  }
  if (length % 4 === 1 || /[^+a-zA-Z0-9/]/.test(input)) {
    return "{}";
  }
  let bitCounter = 0;
  let bitStorage;
  let buffer;
  let output = "";
  let position = -1;
  while (++position < length) {
    buffer = TABLE.indexOf(input.charAt(position));
    bitStorage = bitCounter % 4 ? bitStorage * 64 + buffer : buffer;
    if (bitCounter++ % 4) {
      output += String.fromCharCode(
        0xff & (bitStorage >> ((-2 * bitCounter) & 6))
      );
    }
  }
  const data = JSON.parse(output);
  return data;
};

const AuthContext = createContext({
  getToken: async () => "",
  userData: {
    user_id: "",
    fname: "",
    lname: "",
    email: "",
    phone: "",
    gender: "",
    dob: "",
    height: 0,
    weight: 0,
    pivcInfo: {},
  },
  login: async ({ id_token, refresh_token }) => {},
  logout: () => {},
});

const AuthProvider = (props) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [isLoading, setLoading] = useState(true);
  const [tokenSet, setTokenSet] = useState({
    id_token: "",
    refresh_token: "",
    expiresAt: Date.now(),
  });
  const [userData, setUserData] = useState({
    user_id: "",
    fname: "",
    lname: "",
    email: "",
    phone: "",
    gender: "",
    dob: "",
    height: 0,
    weight: 0,
    pivcInfo: {},
  });

  const login = async ({ id_token, refresh_token }) => {
    setLoading(true);
    try {
      saveTokenSet(id_token, refresh_token);
      const profileData = await getUserData(id_token);
      setUserData(profileData);
      if (profileData.fname?.length > 0) navigate("/", { replace: true });
      else navigate("/basic-details", { replace: true });
    } catch (err) {
      console.error(err);
      setTokenSet({ id_token: "", refresh_token: "", expiresAt: Date.now() });
    } finally {
      setLoading(false);
    }
  };

  const logout = () => {
    setLoading(true);
    try {
      localStorage.removeItem("tokenset");
      setTokenSet({ id_token: "", refresh_token: "", expiresAt: Date.now() });
      navigate("/login", { replace: true });
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  const saveTokenSet = (id_token = "", refresh_token = "") => {
    if (id_token.length <= 0 || refresh_token.length <= 0)
      throw new Error("Invalid Token Set");
    const { exp } = decodeToken(id_token);
    const expiresAt = exp * 1000;
    localStorage.setItem(
      "tokenset",
      btoa(JSON.stringify({ id_token, refresh_token, expiresAt }))
    );
    setTokenSet({ id_token, refresh_token, expiresAt });
  };

  const getUserData = async (token = "") => {
    if (token.length <= 0) throw new Error("Invalid Token");
    const profileResp = await fetch(sdkWrapperURL("/users/profile/view"), {
      method: "POST",
      headers: { "Content-Type": "application/json", Authorization: token },
      body: JSON.stringify({ pivc_include: true }),
    });
    const profileRespJSON = await profileResp.json();
    if (profileRespJSON?.statusCode?.toString().startsWith("2")) {
      return {
        user_id: profileRespJSON.profile.user_id,
        fname: profileRespJSON.profile.first_name,
        lname: profileRespJSON.profile.last_name,
        email: profileRespJSON.profile.email ?? "",
        phone: profileRespJSON.profile.phone_no,
        gender: profileRespJSON.profile.gender,
        dob: profileRespJSON.profile.dob,
        height: profileRespJSON.profile.height,
        weight: profileRespJSON.profile.weight,
        pivcInfo:
          profileRespJSON.profile?.account_configurator?.policy_info ?? {},
      };
    } else
      throw new Error(
        profileRespJSON?.message ?? "Error in Fetching Profile Data"
      );
  };

  const refreshTokenSet = (id_token = "", refresh_token = "") =>
    new Promise(async (resolve, reject) => {
      try {
        if (id_token.length <= 0 || refresh_token.length <= 0)
          throw new Error("Invalid Token Set");
        const resp = await fetch(sdkWrapperURL("/auth/regenerate_token"), {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ id_token, refresh_token }),
        });
        const respJSON = await resp.json();
        if (respJSON.statusCode?.toString().startsWith("2")) {
          if (respJSON.message === "Regeneration of ID token not required")
            resolve({ id_token, refresh_token });
          else
            resolve({
              id_token: respJSON.id_token,
              refresh_token: respJSON.refresh_token,
            });
        } else
          throw new Error(respJSON.message ?? "Error in Regenerating Token");
      } catch (err) {
        reject(err);
      }
    });

  useEffect(() => {
    (async () => {
      setLoading(true);
      try {
        const encoded_tokenset = localStorage.getItem("tokenset");
        if (encoded_tokenset?.length > 0) {
          const decoded_tokenset = JSON.parse(atob(encoded_tokenset));
          const new_tokens = await refreshTokenSet(
            decoded_tokenset.id_token,
            decoded_tokenset.refresh_token
          );
          setUserData(await getUserData(new_tokens.id_token));
          saveTokenSet(new_tokens.id_token, new_tokens.refresh_token);
          if (location.pathname === "/login") navigate("/", { replace: true });
        } else throw new Error("No Stored Token");
      } catch (err) {
        console.error(err);
        setTokenSet({ id_token: "", refresh_token: "", expiresAt: Date.now() });
        if (location.pathname !== "/login")
          navigate("/login", { replace: true });
      } finally {
        setLoading(false);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getToken = () =>
    new Promise(async (resolve, reject) => {
      try {
        if (isLoading) reject(new Error("Loading..."));
        else if (tokenSet.expiresAt > Date.now()) resolve(tokenSet.id_token);
        else {
          const new_tokens = await refreshTokenSet(
            tokenSet.id_token,
            tokenSet.refresh_token
          );
          saveTokenSet(new_tokens.id_token, new_tokens.refresh_token);
          resolve(new_tokens.id_token);
        }
      } catch (err) {
        logout();
        reject(err);
      }
    });

  return isLoading ? (
    <LoadingScreen />
  ) : (
    <AuthContext.Provider value={{ getToken, userData, login, logout }}>
      {props?.children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);

export default AuthProvider;
