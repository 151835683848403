import { Fragment, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { Dialog, Transition } from "@headlessui/react";

import { useAuth } from "../utils/auth";
import { sdkWrapperURL } from "../utils/api-url-list";

import TextInput from "../components/TextInput";
import GenderSelect from "../components/GenderSelect";
import DOBSelect from "../components/DOBSelect";

import { ReactComponent as Loader } from "../assets/icons/Loader.svg";

const BasicDetails = () => {
  const { getToken, logout } = useAuth();

  const [isModalOpen, setModalOpen] = useState(false);
  const [pageNo, setPageNo] = useState(1);

  const fnameRef = useRef(null);
  const lnameRef = useRef(null);
  const emailRef = useRef(null);
  const heightRef = useRef(null);
  const weightRef = useRef(null);

  const [fname, setFName] = useState("");
  const [lname, setLName] = useState("");
  const [email, setEmail] = useState("");
  const [gender, setGender] = useState("");
  const [dob, setDOB] = useState("");
  const [isHeightInCm, setHeightInCm] = useState(false);
  const [heightCm, setHeightCm] = useState("");
  const [heightFt, setHeightFt] = useState("");
  const [heightIn, setHeightIn] = useState("");
  const [isWeightInKg, setWeightInKg] = useState(true);
  const [weightKg, setWeightKg] = useState("");
  const [weightLbs, setWeightLbs] = useState("");

  const [fnameError, setFNameError] = useState("");
  const [lnameError, setLNameError] = useState("");
  const [emailExistsList, setEmailExistsList] = useState(new Set());
  const [emailError, setEmailError] = useState("");
  const [genderError, setGenderError] = useState("");
  const [dobError, setDOBError] = useState("");
  const [heightError, setHeightError] = useState("");
  const [weightError, setWeightError] = useState("");

  const [isAccepted, setAccepted] = useState(false);

  const [isLoading, setLoading] = useState(false);

  const proceed = () => {
    try {
      setFNameError("");
      setLNameError("");
      setEmailError("");
      let error = "";
      const trim_email = email.toLowerCase().trim();
      if (
        trim_email.length > 0 &&
        !/^[a-z0-9]+([._-]*[a-z0-9])+[@](\w+[.])+\w+$/.test(trim_email)
      ) {
        setEmailError(
          "Invalid email address. Please enter a correct email address and try again."
        );
        error = "email";
      } else if (emailExistsList.has(email)) {
        setEmailError("This Email is already used.");
        error = "email";
      }
      setEmail(trim_email);
      const trim_lname = lname.trim();
      if (!/^[a-zA-Z][\w.'’-]*( [\w.'’-]+)?$/.test(trim_lname)) {
        if (trim_lname.length > 0) {
          setLNameError("No special characters are allowed.");
        } else {
          setLName("");
          setLNameError("Please enter your Last Name.");
        }
        error = "lname";
      }
      setLName(trim_lname);
      const trim_fname = fname.trim();
      if (!/^[a-zA-Z][\w.'’-]*( [\w.'’-]+)?$/.test(trim_fname)) {
        if (trim_fname.length > 0) {
          setFNameError("No special characters are allowed.");
        } else {
          setFName("");
          setFNameError("Please enter your First Name.");
        }
        error = "fname";
      }
      setFName(trim_fname);
      if (error.length > 0) {
        switch (error) {
          case "fname":
            fnameRef.current?.focus?.();
            break;
          case "lname":
            lnameRef.current?.focus?.();
            break;
          case "email":
            emailRef.current?.focus?.();
            break;
          default:
            break;
        }
      } else setPageNo(2);
    } catch (err) {
      console.error(err);
    }
  };

  const saveDetails = async () => {
    try {
      setLoading(true);
      setGenderError("");
      setDOBError("");
      setHeightError("");
      setWeightError("");
      let error = "";
      const trim_weight_kg = weightKg.trim();
      const trim_weight_lbs = weightLbs.trim();
      if (
        /^\d{1,3}(\.\d{1,2})?$/.test(
          isWeightInKg ? trim_weight_kg : trim_weight_lbs
        )
      ) {
        if (trim_weight_kg < 20 || trim_weight_kg > 220) {
          if (isWeightInKg)
            setWeightError("Weight must be between 20 and 220 kgs.");
          else setWeightError("Weight must be between 44.09 and 485.02 lbs.");
          error = "weight";
        }
      } else {
        setWeightError("Please enter valid Weight.");
        error = "weight";
      }
      setWeightKg(trim_weight_kg);
      setWeightLbs(trim_weight_lbs);
      const trim_height_cms = heightCm.trim();
      const trim_height_ft = heightFt.trim();
      const trim_height_in = heightIn.trim();
      if (
        isHeightInCm
          ? /^\d{1,3}(\.\d{1,2})?$/.test(trim_height_cms)
          : /^\d$/.test(trim_height_ft) && /^(?:\d|1[01])$/.test(trim_height_in)
      ) {
        if (trim_height_cms < 30 || trim_height_cms > 250) {
          if (isHeightInCm)
            setHeightError("Height must be between 30 and 250 cms.");
          else setHeightError("Height must be between 1ft and 8ft 2inches.");
          error = "height";
        }
      } else {
        setHeightError("Please enter valid Height.");
        error = "height";
      }
      setHeightCm(trim_height_cms);
      setHeightFt(trim_height_ft);
      setHeightIn(trim_height_in);
      if (error !== "height" && error !== "weight") {
        const bmi =
          parseFloat(trim_weight_kg) / (parseFloat(trim_height_cms) / 100) ** 2;
        if (bmi < 10 || bmi > 100) {
          setHeightError("Please enter valid Height.");
          setWeightError("Please enter valid Weight.");
          error = "bmi";
        }
      }
      if (dob.length === 0) {
        setDOBError("Please select your Date of Birth.");
        error = "dob";
      }
      if (!/^(male|female|others)$/.test(gender)) {
        setGenderError("Please select your Gender.");
        error = "gender";
      }
      if (error.length > 0) {
        switch (error) {
          case "height":
            heightRef.current?.focus?.();
            break;
          case "weight":
            weightRef.current?.focus?.();
            break;
          default:
            break;
        }
      } else if (!isAccepted) {
        setModalOpen(true);
      } else {
        const token = await getToken();
        const saveResp = await fetch(sdkWrapperURL("/users/profile/update"), {
          method: "POST",
          headers: { "Content-Type": "application/json", Authorization: token },
          body: JSON.stringify({
            first_name: fname,
            last_name: lname,
            dob: dob,
            is_terms_accepted: isAccepted,
            weight: parseFloat(trim_weight_kg),
            height: parseFloat(trim_height_cms),
            gender: gender.toLowerCase(),
            email: email.toLowerCase(),
          }),
        });
        const saveRespJSON = await saveResp.json();
        if (saveRespJSON?.statusCode?.toString().startsWith("2"))
          window.location.replace("/");
        else if (
          saveRespJSON?.message === "Email already exists in our database."
        ) {
          setEmailExistsList((emailList) => new Set(emailList.add(email)));
          setEmailError("This Email is already used.");
          setPageNo(1);
        } else throw new Error(saveRespJSON?.message ?? "Error in Saving");
      }
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <section className="min-h-screen p-6 relative bg-primary text-white">
      <h2 className="mt-6 mb-4 font-semibold">
        {pageNo === 1 ? (
          <>
            We need few <span className="text-secondary">details,</span>
          </>
        ) : (
          <>
            Almost <span className="text-secondary">done,</span>
          </>
        )}
      </h2>

      <form
        onSubmit={async (e) => {
          e.preventDefault();
          if (!isLoading) {
            if (pageNo === 1) proceed();
            else await saveDetails();
          }
        }}
        noValidate
      >
        {pageNo === 1 ? (
          <div className="space-y-4">
            <TextInput
              inputRef={fnameRef}
              type="text"
              id="fname"
              label="First Name"
              placeholder="Enter your First Name"
              autoComplete="given-name"
              required
              maxLength={25}
              onChangeText={setFName}
              value={fname}
              error={fnameError}
            />
            <TextInput
              inputRef={lnameRef}
              type="text"
              id="lname"
              label="Last Name"
              placeholder="Enter your Last Name"
              autoComplete="family-name"
              required
              maxLength={25}
              onChangeText={setLName}
              value={lname}
              error={lnameError}
            />
            <TextInput
              inputRef={emailRef}
              type="email"
              id="email"
              label="Email"
              placeholder="Enter your Email"
              autoComplete="email"
              inputMode="email"
              onChangeText={setEmail}
              value={email}
              error={emailError}
            />
          </div>
        ) : (
          <div className="space-y-4">
            <GenderSelect
              label="Gender"
              required
              onSelect={(selectedGender) => {
                setGenderError("");
                setGender(selectedGender);
              }}
              value={gender}
              error={genderError}
            />
            <DOBSelect
              id="dob"
              label="Date of Birth"
              placeholder="Select your Date of Birth"
              required
              onSelect={setDOB}
              value={dob}
              error={dobError}
            />
            <div>
              <div className="flex items-center justify-between">
                <label htmlFor="height" className="text-xs">
                  Height ({isHeightInCm ? "cms" : "ft/inches"})
                  <span className="text-error">&nbsp;*</span>
                </label>
                <div className="flex items-stretch justify-between overflow-hidden rounded-md border border-secondary">
                  <button
                    type="button"
                    className={`w-16 px-1.5 py-[3px] rounded-r ${
                      isHeightInCm ? "bg-transparent" : "bg-secondary"
                    } text-white text-xxs`}
                    onClick={() => {
                      setHeightInCm(false);
                      setHeightError("");
                    }}
                  >
                    ft/inches
                  </button>
                  <button
                    type="button"
                    className={`w-16 px-1.5 py-[3px] rounded-l ${
                      isHeightInCm ? "bg-secondary" : "bg-transparent"
                    } text-white text-xxs`}
                    onClick={() => {
                      setHeightInCm(true);
                      setHeightError("");
                    }}
                  >
                    cms
                  </button>
                </div>
              </div>
              {isHeightInCm ? (
                <input
                  ref={heightRef}
                  id="height"
                  name="height"
                  className={`block w-full mt-2 px-4 py-2 rounded-lg outline-none border-2${
                    heightError?.length > 0 ? " border-error" : ""
                  } focus:border-secondary bg-white text-primary text-xs`}
                  placeholder="Enter your Height in cms"
                  type="text"
                  inputMode="decimal"
                  required
                  value={heightCm}
                  onChange={(e) => {
                    const h = e.target.value;
                    setHeightCm(h);
                    const inchesTotal = parseFloat(h) / 2.54;
                    if (isNaN(inchesTotal)) {
                      setHeightFt("");
                      setHeightIn("");
                    } else {
                      const feet = Math.floor(inchesTotal / 12);
                      const inches = Math.round(inchesTotal % 12);
                      const isIn12 = inches === 12;
                      setHeightFt((feet + isIn12).toFixed(0));
                      setHeightIn(isIn12 ? "0" : inches.toFixed(0));
                    }
                  }}
                />
              ) : (
                <div className="mt-2 flex items-stretch justify-between space-x-4">
                  <input
                    ref={heightRef}
                    id="height"
                    name="height"
                    className={`w-[46%] px-4 py-2 rounded-lg outline-none border-2${
                      heightError?.length > 0 ? " border-error" : ""
                    } focus:border-secondary bg-white text-primary text-xs`}
                    placeholder="Enter your Height in ft"
                    type="text"
                    inputMode="decimal"
                    required
                    value={heightFt}
                    onChange={(e) => {
                      const hft = e.target.value;
                      setHeightFt(hft);
                      const cms =
                        (parseInt(hft) * 12 + parseInt(heightIn)) * 2.54;
                      setHeightCm(isNaN(cms) ? "" : cms.toFixed(2));
                    }}
                  />
                  <input
                    id="height2"
                    name="height2"
                    className={`w-[54%] px-4 py-2 rounded-lg outline-none border-2${
                      heightError?.length > 0 ? " border-error" : ""
                    } focus:border-secondary bg-white text-primary text-xs`}
                    placeholder="Enter your Height in inches"
                    type="text"
                    inputMode="decimal"
                    required
                    value={heightIn}
                    onChange={(e) => {
                      const hin = e.target.value;
                      setHeightIn(hin);
                      const cms =
                        (parseInt(heightFt) * 12 + parseInt(hin)) * 2.54;
                      setHeightCm(isNaN(cms) ? "" : cms.toFixed(2));
                    }}
                  />
                </div>
              )}
              {heightError?.length > 0 && (
                <p className="mt-1.5 text-xxs text-error">{heightError}</p>
              )}
            </div>
            <div>
              <div className="flex items-center justify-between">
                <label htmlFor="weight" className="text-xs">
                  Weight ({isWeightInKg ? "kgs" : "lbs"})
                  <span className="text-error">&nbsp;*</span>
                </label>
                <div className="flex items-stretch justify-between overflow-hidden rounded-md border border-secondary">
                  <button
                    type="button"
                    className={`w-16 px-1.5 py-[3px] rounded-r ${
                      isWeightInKg ? "bg-secondary" : "bg-transparent"
                    } text-white text-xxs`}
                    onClick={() => {
                      setWeightInKg(true);
                      setWeightError("");
                    }}
                  >
                    kgs
                  </button>
                  <button
                    type="button"
                    className={`w-16 px-1.5 py-[3px] rounded-l ${
                      isWeightInKg ? "bg-transparent" : "bg-secondary"
                    } text-white text-xxs`}
                    onClick={() => {
                      setWeightInKg(false);
                      setWeightError("");
                    }}
                  >
                    lbs
                  </button>
                </div>
              </div>
              <input
                ref={weightRef}
                id="weight"
                name="weight"
                className={`block w-full mt-2 px-4 py-2 rounded-lg outline-none border-2${
                  weightError.length > 0 ? " border-error" : ""
                } focus:border-secondary bg-white text-primary text-xs`}
                placeholder={`Enter your Weight in ${
                  isWeightInKg ? "kgs" : "lbs"
                }`}
                type="text"
                inputMode="decimal"
                required
                value={isWeightInKg ? weightKg : weightLbs}
                onChange={(e) => {
                  const w = e.target.value;
                  if (isWeightInKg) {
                    setWeightKg(w);
                    const lbs = parseFloat(w) * 2.20462;
                    setWeightLbs(isNaN(lbs) ? "" : lbs.toFixed(2));
                  } else {
                    setWeightLbs(w);
                    const kgs = parseFloat(w) / 2.20462;
                    setWeightKg(isNaN(kgs) ? "" : kgs.toFixed(2));
                  }
                }}
              />
              {weightError.length > 0 && (
                <p className="mt-1.5 text-xxs text-error">{weightError}</p>
              )}
            </div>
            <div className="flex items-center px-1 space-x-2.5">
              <input
                type="checkbox"
                id="terms-n-policy"
                name="terms-n-policy"
                className="outline-none"
                required
                value={isAccepted}
                checked={isAccepted}
                onChange={(e) => setAccepted(e.target.checked)}
              />
              <label htmlFor="terms-n-policy" className="text-xs">
                I accept the&nbsp;
                <Link
                  className={`text-ternary${
                    isLoading ? " pointer-events-none" : ""
                  }`}
                  to="/terms-of-use"
                  target="_blank"
                  rel="noreferrer"
                >
                  terms of use
                </Link>
                &nbsp;&amp;&nbsp;
                <Link
                  className={`text-ternary${
                    isLoading ? " pointer-events-none" : ""
                  }`}
                  to="/privacy-policy"
                  target="_blank"
                  rel="noreferrer"
                >
                  privacy policy
                </Link>
              </label>
            </div>
          </div>
        )}

        <button
          className="mt-8 w-full flex items-center justify-center space-x-2.5 rounded-full outline-none px-5 py-2.5 bg-gradient disabled:bg-secondary text-white"
          type="submit"
          disabled={isLoading}
        >
          <span>{pageNo === 1 ? "Next" : "Proceed"}</span>
          {isLoading && <Loader className="shrink-0 h-4 w-4" />}
        </button>

        <button
          className="block mx-auto mt-4 outline-none text-sm text-center font-medium"
          type="button"
          onClick={pageNo === 1 ? logout : () => setPageNo(1)}
          disabled={isLoading}
        >
          {pageNo === 1 ? "Logout" : "Back"}
        </button>
      </form>
      <Transition show={isModalOpen} as={Fragment}>
        <Dialog as={Fragment} onClose={() => setModalOpen(false)}>
          <Transition.Child
            className="fixed inset-0 bg-black/70 flex items-center justify-center"
            enter="duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="duration-300 delay-150"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Transition.Child
              as={Fragment}
              enter="delay-150 duration-200"
              enterFrom="opacity-0 scale-50"
              enterTo="opacity-100 scale-100"
              leave="duration-100"
              leaveFrom="opacity-50 scale-50"
              leaveTo="opacity-0 scale-0"
            >
              <Dialog.Panel className="w-4/5 bg-white rounded-2xl p-6 flex flex-col items-center text-center">
                <Dialog.Title className="mt-2 text-primary text-sm">
                  Please accept the Terms & Conditions to proceed further.
                </Dialog.Title>
                <button
                  className="mt-4 px-4 py-2 rounded-full outline-none bg-gradient text-white text-xs font-medium"
                  type="button"
                  onClick={() => setModalOpen(false)}
                >
                  Close
                </button>
              </Dialog.Panel>
            </Transition.Child>
          </Transition.Child>
        </Dialog>
      </Transition>
    </section>
  );
};

export default BasicDetails;
