import React from "react";
import { Link, useLocation } from "react-router-dom";
import StartScanIcon from "../assets/images/StartScan.webp";

const StartScan = () => {
  const { state } = useLocation();
  return (
    <section className="min-h-[85vh] px-8 py-2 flex flex-col items-center justify-center text-center">
      <img
        className="w-4/5"
        src={StartScanIcon}
        alt="Start Scan Illustration"
      />
      <h1 className="mt-6 text-xl font-bold">
        Scan vitals to personalize your health experience
      </h1>
      <h3 className="mt-3 font-light">
        Do a self-health check in less than one minute.
      </h3>
      <Link
        className="block mt-6 w-full rounded-full outline-none px-5 py-2.5 bg-gradient text-white"
        {...(Boolean(state?.pivc)
          ? {
              to: "/scan-instructions",
              state: { scanType: "face", ...state },
            }
          : { to: "/select-scan", replace: true })}
      >
        Start Scan
      </Link>
      <Link to={-1} className="block mt-4 text-sm font-medium">
        Back
      </Link>
    </section>
  );
};

export default StartScan;
