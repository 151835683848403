import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import { useAuth } from "../utils/auth";
import { sdkWrapperURL } from "../utils/api-url-list";

import LoadingScreen from "../components/LoadingScreen";

import StartScanBanner from "../assets/images/StartScanBanner.webp";
import { ReactComponent as GlowingHeart } from "../assets/icons/GlowingHeart.svg";
import { ReactComponent as Chevron } from "../assets/icons/Chevron.svg";

const Dashboard = () => {
  const {
    getToken,
    userData: { user_id },
  } = useAuth();
  const [loading, setLoading] = useState(true);
  const [lastScanResult, setLastResult] = useState({});
  const [scoreChange, setScoreChange] = useState(0);

  useEffect(() => {
    const controller = new AbortController();
    setLoading(true);
    (async () => {
      try {
        const token = await getToken();
        const scoreResp = await fetch(
          sdkWrapperURL("/users/sdk/test_api_private"),
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: token,
            },
            body: JSON.stringify({
              action: "list_scan_UL",
              employee_id: user_id,
              table_config_rows_per_page: 2,
              table_config_page_no: 1,
            }),
            signal: controller.signal,
          }
        );
        const scoreRespJSON = await scoreResp.json();
        if (scoreRespJSON?.statusCode?.toString().startsWith("2")) {
          if (scoreRespJSON.result?.length > 0) {
            if (scoreRespJSON.result.length > 1)
              setScoreChange(
                scoreRespJSON.result[0].wellness_score -
                  scoreRespJSON.result[1].wellness_score
              );
            setLastResult(scoreRespJSON.result[0]);
          } else throw new Error("No recent scan data.");
        } else
          throw new Error(
            scoreRespJSON?.message ?? "Failed to fetch recent scan data."
          );
      } catch (err) {
        console.error(err);
      } finally {
        setLoading(false);
      }
    })();
    return () => {
      controller.abort();
    };
  }, [getToken, user_id]);

  return loading ? (
    <LoadingScreen style={{ minHeight: "60vh" }} />
  ) : (
    <section className="px-6 py-2">
      <div className="flex items-center space-x-4 px-6 py-4 rounded-2xl bg-gradient-transparent text-white">
        <GlowingHeart className="shrink-0 h-28 w-28" />
        <div>
          <p className="flex items-center space-x-2">
            <span className="text-3xl font-bold">
              {lastScanResult.wellness_score}
            </span>
            {scoreChange !== 0 && (
              <span
                className={`${
                  scoreChange > 0 ? "text-success" : "text-error"
                } text-xs font-semibold`}
              >
                {scoreChange > 0 ? "↑" : "↓"}&nbsp;
                {Math.abs(scoreChange)}%
              </span>
            )}
          </p>
          <p className="text-secondary font-semibold leading-tight">
            Digital Health Assessment Score
          </p>
          <p className="mt-1 text-xxs font-light leading-snug whitespace-pre-line">
            {lastScanResult.created_at?.length > 0
              ? `Last updated:\n${new Date(
                  lastScanResult.created_at
                ).toLocaleDateString(undefined, {
                  month: "long",
                  day: "numeric",
                  year: "numeric",
                })}, ${new Date(lastScanResult.created_at).toLocaleTimeString(
                  undefined,
                  { hour: "2-digit", minute: "2-digit", hour12: true }
                )}`
              : "No scan data avaiable"}
          </p>
        </div>
      </div>

      <Link
        to="/start-scan"
        className="my-6 flex items-center space-x-4 px-6 py-4 rounded-2xl bg-gradient-transparent text-white"
      >
        <div>
          <h3 className="text-sm font-bold leading-tight tracking-tight">
            Scan vitals to personalize your health experience
          </h3>
          <p className="mt-1.5 text-xxs leading-tight tracking-tight">
            Do a self-health check in
            <br />
            less than one minute.
          </p>
          <p className="max-w-40 mt-3 px-4 py-2 rounded-full bg-ternary text-sm text-center">
            Start Scan
          </p>
        </div>
        <img
          className="shrink-0 h-[7.5rem]"
          src={StartScanBanner}
          alt="Banner"
        />
      </Link>

      {lastScanResult.created_at?.length > 0 && (
        <Link
          to="/scan-result"
          state={{
            result: {
              scan_id: lastScanResult.scan_id,
              posture: lastScanResult.posture,
              wellness_score: lastScanResult.wellness_score,
              vitals: lastScanResult.vitals,
              metadata: {
                heart_scores: lastScanResult.heart_scores,
                cardiovascular: lastScanResult.cardiovascular,
                physiological_scores: lastScanResult.physiological_scores,
                glucose_info: lastScanResult.glucose_info,
              },
            },
          }}
          className="flex items-center justify-between px-6 py-4 rounded-2xl border border-secondary/60 bg-primary text-white text-sm font-medium"
        >
          <span>View Last Assessment Result</span>
          <Chevron className="shrink-0 h-4 w-4 -rotate-90" />
        </Link>
      )}

      <div className="my-8 space-y-4">
        <h2 className="text-sm font-semibold">What's New?</h2>
        <iframe
          className="rounded-xl w-full aspect-video outline-none bg-primary"
          title="Product Video"
          src="https://www.youtube.com/embed/LbJ70TaXErA?color=white&playsinline=1&fs=0&disablekb=1&rel=0&cc_load_policy=0&iv_load_policy=3"
        />
        <p className="text-xs text-justify font-light">
          Introducing the latest innovation in HbA1c testing: a convenient and
          non-invasive method utilizing face or finger scans. This cutting-edge
          technology offers a hassle-free and accessible approach to monitoring
          HbA1c levels, revolutionizing diabetes management for individuals
          worldwide. Currently in the beta phase, this new launch represents a
          significant step forward in improving diabetes care and empowering
          individuals to take control of their health.
        </p>
      </div>
    </section>
  );
};

export default Dashboard;
