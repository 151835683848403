import { useRef, useState } from "react";
import { Link } from "react-router-dom";

import TextInput from "../components/TextInput";
import GenderSelect from "../components/GenderSelect";
import DOBSelect from "../components/DOBSelect";

import { useAuth } from "../utils/auth";
import { sdkWrapperURL } from "../utils/api-url-list";

import { ReactComponent as BackArrow } from "../assets/icons/BackArrow.svg";
import { ReactComponent as Loader } from "../assets/icons/Loader.svg";

const MyAccount = () => {
  const { getToken, userData } = useAuth();

  const fnameRef = useRef(null);
  const lnameRef = useRef(null);
  const emailRef = useRef(null);
  const heightRef = useRef(null);
  const weightRef = useRef(null);

  const [fname, setFName] = useState(userData.fname);
  const [lname, setLName] = useState(userData.lname);
  const [email, setEmail] = useState(userData.email);
  const [gender, setGender] = useState(userData.gender);
  const [dob, setDOB] = useState(userData.dob);
  const [isHeightInCm, setHeightInCm] = useState(false);
  const [heightCm, setHeightCm] = useState(userData.height?.toFixed?.(2) ?? "");
  const [heightFt, setHeightFt] = useState(() => {
    const inchesTotal = parseFloat(userData.height?.toString?.()) / 2.54;
    if (isNaN(inchesTotal)) return "";
    else {
      const feet = Math.floor(inchesTotal / 12);
      const inches = Math.round(inchesTotal % 12);
      return (feet + (inches === 12)).toFixed(0);
    }
  });
  const [heightIn, setHeightIn] = useState(() => {
    const inchesTotal = parseFloat(userData.height?.toString?.()) / 2.54;
    if (isNaN(inchesTotal)) return "";
    else {
      const inches = Math.round(inchesTotal % 12);
      return inches === 12 ? "0" : inches.toFixed(0);
    }
  });
  const [isWeightInKg, setWeightInKg] = useState(true);
  const [weightKg, setWeightKg] = useState(userData.weight?.toFixed?.(2) ?? "");
  const [weightLbs, setWeightLbs] = useState(() => {
    const lbs = parseFloat(userData.weight?.toString?.()) * 2.20462;
    return isNaN(lbs) ? "" : lbs.toFixed(2);
  });

  const [fnameError, setFNameError] = useState("");
  const [lnameError, setLNameError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [genderError, setGenderError] = useState("");
  const [dobError, setDOBError] = useState("");
  const [heightError, setHeightError] = useState("");
  const [weightError, setWeightError] = useState("");

  const [isLoading, setLoading] = useState(false);

  const updateProfile = async () => {
    try {
      setLoading(true);
      setFNameError("");
      setLNameError("");
      setEmailError("");
      setGenderError("");
      setDOBError("");
      setHeightError("");
      setWeightError("");
      let error = "";
      const trim_weight_kg = weightKg.trim();
      const trim_weight_lbs = weightLbs.trim();
      if (
        /^\d{1,3}(\.\d{1,2})?$/.test(
          isWeightInKg ? trim_weight_kg : trim_weight_lbs
        )
      ) {
        if (trim_weight_kg < 20 || trim_weight_kg > 220) {
          if (isWeightInKg)
            setWeightError("Weight must be between 20 and 220 kgs.");
          else setWeightError("Weight must be between 44.09 and 485.02 lbs.");
          error = "weight";
        }
      } else {
        setWeightError("Please enter valid Weight.");
        error = "weight";
      }
      setWeightKg(trim_weight_kg);
      setWeightLbs(trim_weight_lbs);
      const trim_height_cms = heightCm.trim();
      const trim_height_ft = heightFt.trim();
      const trim_height_in = heightIn.trim();
      if (
        isHeightInCm
          ? /^\d{1,3}(\.\d{1,2})?$/.test(trim_height_cms)
          : /^\d$/.test(trim_height_ft) && /^(?:\d|1[01])$/.test(trim_height_in)
      ) {
        if (trim_height_cms < 30 || trim_height_cms > 250) {
          if (isHeightInCm)
            setHeightError("Height must be between 30 and 250 cms.");
          else setHeightError("Height must be between 1ft and 8ft 2inches.");
          error = "height";
        }
      } else {
        setHeightError("Please enter valid Height.");
        error = "height";
      }
      setHeightCm(trim_height_cms);
      setHeightFt(trim_height_ft);
      setHeightIn(trim_height_in);
      if (error !== "height" && error !== "weight") {
        const bmi =
          parseFloat(trim_weight_kg) / (parseFloat(trim_height_cms) / 100) ** 2;
        if (bmi < 10 || bmi > 100) {
          setHeightError("Please enter valid Height.");
          setWeightError("Please enter valid Weight.");
          error = "bmi";
        }
      }
      if (dob.length === 0) {
        setDOBError("Please select your Date of Birth.");
        error = "dob";
      }
      if (!/^(male|female|others)$/.test(gender)) {
        setGenderError("Please select your Gender.");
        error = "gender";
      }
      const trim_email = email.toLowerCase().trim();
      if (
        trim_email.length > 0 &&
        !/^[a-z0-9]+([._-]*[a-z0-9])+[@](\w+[.])+\w+$/.test(trim_email)
      ) {
        setEmailError(
          "Invalid email address. Please enter a correct email address and try again."
        );
        error = "email";
      }
      setEmail(trim_email);
      const trim_lname = lname.trim();
      if (!/^[a-zA-Z][\w.'’-]*( [\w.'’-]+)?$/.test(trim_lname)) {
        if (trim_lname.length > 0) {
          setLNameError("No special characters are allowed.");
        } else {
          setLName("");
          setLNameError("Please enter your Last Name.");
        }
        error = "lname";
      }
      setLName(trim_lname);
      const trim_fname = fname.trim();
      if (!/^[a-zA-Z][\w.'’-]*( [\w.'’-]+)?$/.test(trim_fname)) {
        if (trim_fname.length > 0) {
          setFNameError("No special characters are allowed.");
        } else {
          setFName("");
          setFNameError("Please enter your First Name.");
        }
        error = "fname";
      }
      setFName(trim_fname);
      if (error.length > 0) {
        switch (error) {
          case "fname":
            fnameRef.current?.focus?.();
            break;
          case "lname":
            lnameRef.current?.focus?.();
            break;
          case "email":
            emailRef.current?.focus?.();
            break;
          case "height":
            heightRef.current?.focus?.();
            break;
          case "weight":
            weightRef.current?.focus?.();
            break;
          default:
            break;
        }
      } else {
        const token = await getToken();
        const saveResp = await fetch(sdkWrapperURL("/users/profile/update"), {
          method: "POST",
          headers: { "Content-Type": "application/json", Authorization: token },
          body: JSON.stringify({
            first_name: fname,
            last_name: lname,
            dob: dob,
            is_terms_accepted: true,
            weight: parseFloat(trim_weight_kg),
            height: parseFloat(trim_height_cms),
            gender: gender.toLowerCase(),
            email: email.toLowerCase(),
          }),
        });
        const saveRespJSON = await saveResp.json();
        if (saveRespJSON?.statusCode?.toString().startsWith("2"))
          window.location.reload(true);
        else if (
          saveRespJSON?.message === "Email already exists in our database."
        )
          setEmailError("This Email is already used.");
        else throw new Error(saveRespJSON?.message ?? "Error in Saving");
      }
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <section className="px-6 py-2">
      <div className="flex items-center">
        <Link className="shrink-0 p-1 pr-2.5" to={-1}>
          <BackArrow className="h-2.5 w-2.5" />
        </Link>
        <h3 className="text-sm font-medium">My Account</h3>
      </div>

      <form
        className="my-6"
        onSubmit={async (e) => {
          e.preventDefault();
          if (!isLoading) await updateProfile();
        }}
        noValidate
      >
        <div className="space-y-4">
          <TextInput
            inputRef={fnameRef}
            type="text"
            id="fname"
            label="First Name"
            placeholder="Enter your First Name"
            autoComplete="given-name"
            required
            maxLength={25}
            onChangeText={setFName}
            value={fname}
            error={fnameError}
          />
          <TextInput
            inputRef={lnameRef}
            type="text"
            id="lname"
            label="Last Name"
            placeholder="Enter your Last Name"
            autoComplete="family-name"
            required
            maxLength={25}
            onChangeText={setLName}
            value={lname}
            error={lnameError}
          />
          <TextInput
            inputRef={emailRef}
            type="email"
            id="email"
            label="Email"
            placeholder="Enter your Email"
            autoComplete="email"
            inputMode="email"
            onChangeText={setEmail}
            value={email}
            error={emailError}
          />
          <GenderSelect
            label="Gender"
            required
            onSelect={(selectedGender) => {
              setGenderError("");
              setGender(selectedGender);
            }}
            value={gender}
            error={genderError}
          />
          <DOBSelect
            id="dob"
            label="Date of Birth"
            placeholder="Select your Date of Birth"
            required
            onSelect={setDOB}
            value={dob}
            error={dobError}
          />
          <div>
            <div className="flex items-center justify-between">
              <label htmlFor="height" className="text-xs">
                Height ({isHeightInCm ? "cms" : "ft/inches"})
                <span className="text-error">&nbsp;*</span>
              </label>
              <div className="flex items-stretch justify-between overflow-hidden rounded-md border border-secondary">
                <button
                  type="button"
                  className={`w-16 px-1.5 py-[3px] rounded-r ${
                    isHeightInCm ? "bg-transparent" : "bg-secondary"
                  } text-white text-xxs`}
                  onClick={() => {
                    setHeightInCm(false);
                    setHeightError("");
                  }}
                >
                  ft/inches
                </button>
                <button
                  type="button"
                  className={`w-16 px-1.5 py-[3px] rounded-l ${
                    isHeightInCm ? "bg-secondary" : "bg-transparent"
                  } text-white text-xxs`}
                  onClick={() => {
                    setHeightInCm(true);
                    setHeightError("");
                  }}
                >
                  cms
                </button>
              </div>
            </div>
            {isHeightInCm ? (
              <input
                ref={heightRef}
                id="height"
                name="height"
                className={`block w-full mt-2 px-4 py-2 rounded-lg outline-none border-2${
                  heightError?.length > 0 ? " border-error" : ""
                } focus:border-secondary bg-white text-primary text-xs`}
                placeholder="Enter your Height in cms"
                type="text"
                inputMode="decimal"
                required
                value={heightCm}
                onChange={(e) => {
                  const h = e.target.value;
                  setHeightCm(h);
                  const inchesTotal = parseFloat(h) / 2.54;
                  if (isNaN(inchesTotal)) {
                    setHeightFt("");
                    setHeightIn("");
                  } else {
                    const feet = Math.floor(inchesTotal / 12);
                    const inches = Math.round(inchesTotal % 12);
                    const isIn12 = inches === 12;
                    setHeightFt((feet + isIn12).toFixed(0));
                    setHeightIn(isIn12 ? "0" : inches.toFixed(0));
                  }
                }}
              />
            ) : (
              <div className="mt-2 flex items-stretch justify-between space-x-4">
                <input
                  ref={heightRef}
                  id="height"
                  name="height"
                  className={`w-[46%] px-4 py-2 rounded-lg outline-none border-2${
                    heightError?.length > 0 ? " border-error" : ""
                  } focus:border-secondary bg-white text-primary text-xs`}
                  placeholder="Enter your Height in ft"
                  type="text"
                  inputMode="decimal"
                  required
                  value={heightFt}
                  onChange={(e) => {
                    const hft = e.target.value;
                    setHeightFt(hft);
                    const cms =
                      (parseInt(hft) * 12 + parseInt(heightIn)) * 2.54;
                    setHeightCm(isNaN(cms) ? "" : cms.toFixed(2));
                  }}
                />
                <input
                  id="height2"
                  name="height2"
                  className={`w-[54%] px-4 py-2 rounded-lg outline-none border-2${
                    heightError?.length > 0 ? " border-error" : ""
                  } focus:border-secondary bg-white text-primary text-xs`}
                  placeholder="Enter your Height in inches"
                  type="text"
                  inputMode="decimal"
                  required
                  value={heightIn}
                  onChange={(e) => {
                    const hin = e.target.value;
                    setHeightIn(hin);
                    const cms =
                      (parseInt(heightFt) * 12 + parseInt(hin)) * 2.54;
                    setHeightCm(isNaN(cms) ? "" : cms.toFixed(2));
                  }}
                />
              </div>
            )}
            {heightError?.length > 0 && (
              <p className="mt-1.5 text-xxs text-error">{heightError}</p>
            )}
          </div>
          <div>
            <div className="flex items-center justify-between">
              <label htmlFor="weight" className="text-xs">
                Weight ({isWeightInKg ? "kgs" : "lbs"})
                <span className="text-error">&nbsp;*</span>
              </label>
              <div className="flex items-stretch justify-between overflow-hidden rounded-md border border-secondary">
                <button
                  type="button"
                  className={`w-16 px-1.5 py-[3px] rounded-r ${
                    isWeightInKg ? "bg-secondary" : "bg-transparent"
                  } text-white text-xxs`}
                  onClick={() => {
                    setWeightInKg(true);
                    setWeightError("");
                  }}
                >
                  kgs
                </button>
                <button
                  type="button"
                  className={`w-16 px-1.5 py-[3px] rounded-l ${
                    isWeightInKg ? "bg-transparent" : "bg-secondary"
                  } text-white text-xxs`}
                  onClick={() => {
                    setWeightInKg(false);
                    setWeightError("");
                  }}
                >
                  lbs
                </button>
              </div>
            </div>
            <input
              ref={weightRef}
              id="weight"
              name="weight"
              className={`block w-full mt-2 px-4 py-2 rounded-lg outline-none border-2${
                weightError.length > 0 ? " border-error" : ""
              } focus:border-secondary bg-white text-primary text-xs`}
              placeholder={`Enter your Weight in ${
                isWeightInKg ? "kgs" : "lbs"
              }`}
              type="text"
              inputMode="decimal"
              required
              value={isWeightInKg ? weightKg : weightLbs}
              onChange={(e) => {
                const w = e.target.value;
                if (isWeightInKg) {
                  setWeightKg(w);
                  const lbs = parseFloat(w) * 2.20462;
                  setWeightLbs(isNaN(lbs) ? "" : lbs.toFixed(2));
                } else {
                  setWeightLbs(w);
                  const kgs = parseFloat(w) / 2.20462;
                  setWeightKg(isNaN(kgs) ? "" : kgs.toFixed(2));
                }
              }}
            />
            {weightError.length > 0 && (
              <p className="mt-1.5 text-xxs text-error">{weightError}</p>
            )}
          </div>
        </div>
        <button
          className="mt-8 mx-auto w-40 flex items-center justify-center space-x-2.5 rounded-full outline-none px-5 py-2.5 bg-gradient disabled:bg-secondary text-white"
          type="submit"
          disabled={isLoading}
        >
          <span>Update</span>
          {isLoading && <Loader className="shrink-0 h-4 w-4" />}
        </button>
      </form>
    </section>
  );
};

export default MyAccount;
