import Loader from "../assets/images/loader.gif";

const LoadingScreen = ({ style = {} }) => (
  <div
    className="min-h-screen flex justify-center items-center bg-primary text-white text-xl text-center"
    style={style}
  >
    <img className="w-3/5" src={Loader} alt="Loading..." />
  </div>
);

export default LoadingScreen;
