import { useEffect, useMemo, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import * as facescan from "careplix-scan-sdk/facescan";
import { useAuth } from "../utils/auth";
import {
  scan_sdk_api_key as api_key,
  sdkURL,
  sdkWrapperURL,
} from "../utils/api-url-list";

import { ReactComponent as CarePlix } from "../assets/icons/PoweredByCarePlix.svg";
import { ReactComponent as GlowingHeart } from "../assets/icons/GlowingHeart.svg";
import { ReactComponent as SwitchCamera } from "../assets/icons/SwitchCamera.svg";
import Loader from "../assets/images/loader.gif";
import Analyzing from "../assets/images/analyzing.webp";
import Failure from "../assets/images/failure.webp";
import NoCamera from "../assets/images/no-camera.webp";

const FaceScan = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const { getToken, userData } = useAuth();
  const videoRef = useRef();
  const canvasRef = useRef();

  const [loading, setLoading] = useState(true);
  const [analyzing, setAnalyzing] = useState(false);
  const [noCameraError, setNoCameraError] = useState(false);
  const [error, setError] = useState("");
  const [scanFrameData, setScanFrameData] = useState({
    type: "error",
    message: "---",
    progress: 0,
    timeElapsed: 0,
    isLiteMode: false,
  });

  const timers = useRef([]);
  const scanImages = useRef([]);

  useEffect(() => {
    if (
      state?.pivc &&
      scanFrameData.type === "scan" &&
      !(timers.current?.length > 0)
    ) {
      const captureImageAt = (time = 0) =>
        setTimeout(() => {
          canvasRef.current?.toBlob?.(
            (blob) => {
              scanImages.current.push(
                new File([blob], `scan-image-at-${time}.jpg`, {
                  type: blob.type,
                })
              );
            },
            "image/jpeg",
            0.8
          );
        }, time * 1000);
      timers.current = [
        captureImageAt(15),
        captureImageAt(30),
        captureImageAt(45),
      ];
    }
    return () => {
      scanImages.current = [];
      if (timers.current?.length > 0) {
        timers.current.forEach((t) => {
          clearTimeout(t);
        });
        timers.current = [];
      }
    };
  }, [state?.pivc, scanFrameData.type]);

  useEffect(() => {
    setLoading(true);
    facescan.onFrame(setScanFrameData);
    facescan.onError((err) => {
      console.error(err);
      if (
        err.message ===
        "We are not able to access the Camera. Please try again."
      )
        setNoCameraError(true);
      else setError(err.message);
    });
    facescan.onScanFinish(async ({ raw_intensity, ppg_time, average_fps }) => {
      try {
        setAnalyzing(true);
        const token = await getToken();
        const scanTokenResp = await fetch(
          sdkWrapperURL("/users/sdk/test_api_private"),
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: token,
            },
            body: JSON.stringify({
              action: "create_scan_UL",
              employee_id: userData.user_id,
            }),
          }
        );
        const scanTokenRespJSON = await scanTokenResp.json();
        if (scanTokenRespJSON?.statusCode?.toString().startsWith("2")) {
          const addScanResp = await fetch(sdkURL("/vitals/add-scan"), {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
              api_key,
              scan_token: scanTokenRespJSON.scan_token,
              employee_id: userData.user_id,
              posture: "resting",
              dob: userData.dob,
              gender: userData.gender,
              metadata: {
                physiological_scores: {
                  height: userData.height,
                  weight: userData.weight,
                },
                ppg_time: ppg_time,
                raw_intensity: raw_intensity,
                device: `RPPG_CAREPLIX_FACE_${
                  /iPhone|iPad|iPod|Macintosh|Mac/i.test(navigator.userAgent)
                    ? "IOS"
                    : "ANDROID"
                }`,
                fps: average_fps,
              },
              notes: state?.pivc ? { pivc_include: true } : undefined,
            }),
          });
          const addScanRespJSON = await addScanResp.json();
          if (addScanRespJSON?.statusCode?.toString().startsWith("2")) {
            if (state?.pivc) {
              const scanImagesResp = await fetch(
                sdkWrapperURL("/pivc/v1/upload-image"),
                {
                  method: "POST",
                  headers: {
                    "Content-Type": "application/json",
                    Authorization: token,
                  },
                  body: JSON.stringify({
                    scan_id: addScanRespJSON.scan_id,
                    durations: [15, 30, 45],
                    file_extension: "jpg",
                    pivc_id: state?.pivcID,
                  }),
                }
              );
              const scanImagesRespJSON = await scanImagesResp.json();
              if (scanImagesRespJSON?.statusCode?.toString().startsWith("2")) {
                const urls = scanImagesRespJSON.body.presigned_urls;
                for (let i = 0; i < urls.length; i++) {
                  const fileFormdata = new FormData();
                  Object.entries(urls[i]?.fields ?? {}).map(([key, value]) =>
                    fileFormdata.append(key, value)
                  );
                  fileFormdata.append("file", scanImages.current?.[i]);
                  const uploadResp = await fetch(urls[i]?.url, {
                    method: "POST",
                    body: fileFormdata,
                  });
                  if (!uploadResp.ok)
                    throw new Error(`Error Occurred while upload the images.`);
                }
                navigate("/pivc/status", {
                  replace: true,
                  state: {
                    title: "Face Scan Completed",
                    subtitile: "Proceed to the last step of verification",
                    next: {
                      to: "/pivc/video-consent",
                      state: { pivcID: state?.pivcID },
                    },
                  },
                });
              } else
                throw new Error(
                  scanImagesRespJSON?.message ?? "Failed to Upload Scan Images"
                );
            } else
              navigate("/scan-result", {
                state: { result: addScanRespJSON },
                replace: true,
              });
          } else
            throw new Error(
              addScanRespJSON?.message ?? "Failed to Fetch Result"
            );
        } else
          throw new Error(
            scanTokenRespJSON?.message ?? "Failed to Fetch Scan Token"
          );
      } catch (err) {
        console.error(err);
        setError(err.message);
      }
    });
    facescan
      .startScan({
        canvasElement: canvasRef.current,
        videoElement: videoRef.current,
      })
      .then(() => console.log("Scan Started"))
      .catch(() => console.log("Scan Failed"))
      .finally(() => setLoading(false));
    return () => {
      facescan.stopScan();
    };
  }, [navigate, getToken, userData, state]);

  const scanMessages = useMemo(
    () =>
      [
        "We burn calories while we are asleep because brain activity requires energy",
        "Exercise promotes cardiovascular health, ensuring more blood and oxygen to circulate the body, helping to elevate energy",
        "Always sleep on your back straight since it allows your neck and spine in a neutral position",
        "Alcohol consumption disrupts chemical impulses between brain cells causing impulsive behaviour, slurred speech, impaired memory",
        "Meditation increases nitric oxide generation in the brain helping to lower your blood pressure",
        "Meditation reduces the production of cytokines in several recent studies, thus helping in stress reduction",
        "Laughing is good for your heart. It reduces stress and gives a boost to your immune system",
        "Lack of sleep can lead to heart disease, heart attack, or stroke",
        "Heart pumps about 2,000 gallons of blood every day",
        "Your heart will beat about 115,000 times each day",
      ].sort(() => Math.random() - 0.5),
    []
  );

  const currentMessage = useMemo(() => {
    if (scanFrameData.type === "error") return "---";
    const timeElapsed = scanFrameData.timeElapsed / 1000;
    if (timeElapsed <= 5)
      return "Keep your head upright and look straight at the camera";
    else if (timeElapsed <= 10)
      return "During the measurement, please do not speak or move";
    else if (timeElapsed <= 15)
      return "Keep your device steady throughout the vital measurement";
    else if (timeElapsed <= 20) return scanMessages[0];
    else if (timeElapsed <= 25) return scanMessages[1];
    else if (timeElapsed <= 30) return scanMessages[2];
    else if (timeElapsed <= 35) return scanMessages[3];
    else if (timeElapsed <= 40) return scanMessages[4];
    else if (timeElapsed <= 45) return scanMessages[5];
    else if (timeElapsed <= 50) return scanMessages[6];
    else if (timeElapsed <= 55) return scanMessages[7];
    else if (timeElapsed <= 60) return scanMessages[8];
    else if (timeElapsed <= 65) return scanMessages[9];
  }, [scanFrameData.timeElapsed, scanFrameData.type, scanMessages]);

  return (
    <section className="relative h-screen w-screen">
      <video
        ref={videoRef}
        id="videoInput"
        className="fixed top-4 right-4 h-px w-px"
        autoPlay
        muted
        playsInline
      />
      <canvas
        ref={canvasRef}
        id="canvasOutput"
        className="w-full h-full -scale-x-100"
      />

      {scanFrameData.type === "scan" && (
        <div className="fixed left-0 right-0 h-12 animate-scan from-[#5D14B0]/75" />
      )}

      {scanFrameData.type === "error" &&
        (scanFrameData.message.includes("Detection of low FPS.") ? (
          <div className="fixed top-0 bottom-0 left-0 right-0 px-8 py-12 flex flex-col items-center justify-center bg-primary text-white">
            <div className="space-y-6">
              <SwitchCamera className="h-16 w-16" />
              <p className="text-xl font-light whitespace-pre-line">
                {scanFrameData.message.replace(/\. /g, ".\n")}
              </p>
            </div>
          </div>
        ) : (
          <p className="fixed top-[40vh] -translate-y-1/2 left-8 right-8 px-1 py-0.5 rounded-full bg-error/70 text-white text-center text-sm font-medium">
            {scanFrameData.message}
          </p>
        ))}

      <div className="fixed top-0 left-0 right-0 p-4 pl-1 bg-primary text-white">
        <CarePlix className="h-14" />
        <GlowingHeart className="fixed top-4 right-4 h-32 w-32" />
      </div>

      <div className="fixed bottom-0 left-0 right-0 min-h-48 flex flex-col bg-primary text-white">
        <div className="relative shrink-0 h-2.5 border-y border-primary bg-white">
          <div
            className="absolute left-0 top-0 bottom-0 rounded-r-full bg-success shadow-inner transition-all duration-1000"
            style={{ width: `${scanFrameData.progress}%` }}
          />
        </div>
        <div className="relative grow m-4 p-4 pb-12 rounded-2xl bg-gradient-transparent">
          <h4 className="font-semibold">
            {scanFrameData.type === "error"
              ? "Scan Stopped"
              : scanFrameData.message}
          </h4>
          <h6 className="mt-2.5 text-xs leading-tight tracking-tight">
            {currentMessage}
          </h6>
          <button
            type="button"
            onClick={() => {
              facescan.stopScan();
              navigate(-1);
            }}
            className="absolute bottom-4 right-4 text-error text-sm font-medium"
          >
            &lt;&nbsp;Cancel Scan
          </button>
        </div>
      </div>

      {analyzing && (
        <div className="fixed top-0 bottom-0 left-0 right-0 px-8 py-12 flex flex-col items-center justify-center bg-primary text-white text-center">
          <img className="shrink-0 h-32" src={Analyzing} alt="Analyzing..." />
          <h4 className="mt-8 text-xl font-bold">Analyzing Data</h4>
          <p className="mt-4 font-light">
            Continuing analysis for
            <br />
            accurate measurements...
          </p>
        </div>
      )}

      {error.length > 0 && (
        <div className="fixed top-0 bottom-0 left-0 right-0 px-8 py-12 flex flex-col items-center justify-center bg-primary text-white text-center">
          <img className="shrink-0 h-44" src={Failure} alt="Failure..." />
          <p className="mt-8 text-xl font-bold">
            Sorry, we failed to calculate
            <br />
            your measurements
          </p>
          <p className="mt-4 max-h-40 overflow-auto whitespace-pre-line font-light">
            {error}
          </p>
          <button
            className="mt-8 w-60 px-5 py-2.5 rounded-full bg-gradient text-white font-medium"
            type="button"
            onClick={() => {
              facescan.stopScan();
              navigate(-1);
            }}
          >
            Try Again
          </button>
          <button
            className="mt-4 text-white text-sm font-medium"
            type="button"
            onClick={() => {
              facescan.stopScan();
              navigate(-3);
            }}
          >
            Back to Dashboard
          </button>
        </div>
      )}

      {noCameraError && (
        <div className="fixed top-0 bottom-0 left-0 right-0 px-8 py-12 flex flex-col items-center justify-center bg-primary text-white text-center">
          <img className="shrink-0 h-44" src={NoCamera} alt="No Camera..." />
          <p className="mt-8 text-xl font-bold">
            No Camera
            <br />
            Permission Found
          </p>
          <p className="mt-4 max-h-40 overflow-auto whitespace-pre-line font-light">
            We are not able to access the Camera. Please try again.
          </p>
          <button
            className="mt-8 px-5 py-2.5 rounded-full bg-gradient text-white font-medium"
            type="button"
            onClick={() => {
              facescan.stopScan();
              navigate("/camera-permission-guide", { replace: true });
            }}
          >
            How to give camera permission?
          </button>
          <button
            className="mt-4 text-white text-sm font-medium"
            type="button"
            onClick={() => {
              facescan.stopScan();
              navigate(-3);
            }}
          >
            Back to Dashboard
          </button>
        </div>
      )}

      {loading && (
        <div className="fixed top-0 bottom-0 left-0 right-0 px-8 py-12 flex flex-col items-center justify-center bg-primary text-white text-center">
          <img className="shrink-0 h-20" src={Loader} alt="Loading..." />
          <h4 className="mt-20 text-xl font-bold">Downloading Scan modules</h4>
          <p className="mt-4 font-light">This might take few seconds....</p>
        </div>
      )}
    </section>
  );
};

export default FaceScan;
