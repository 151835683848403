import { useCallback, useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { useAuth } from "../../utils/auth";
import { sdkWrapperURL } from "../../utils/api-url-list";
import { ReactComponent as GlowingHeart } from "../../assets/icons/GlowingHeart.svg";
import Backarrow from "../../assets/icons/arrow-right.svg";
import { ReactComponent as NoResult } from "../../assets/icons/NoResult.svg";
import { ReactComponent as Check } from "../../assets/icons/Check.svg";
import { ReactComponent as Close } from "../../assets/icons/Close.svg";

const ScanCompleted = () => {
  const { getToken } = useAuth();
  const { state } = useLocation();
  const [result, setResult] = useState(undefined);
  const [loading, setLoading] = useState(false);

  const fetchResult = useCallback(async () => {
    setLoading(true);
    const token = await getToken();
    const resp = await fetch(sdkWrapperURL("/pivc/v1/check-status"), {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
      body: JSON.stringify({}),
    });
    const respJSON = await resp.json();
    if (respJSON?.statusCode?.toString().startsWith("2"))
      if (respJSON.pivc_status === "SUCCESS")
        return {
          pivc_id: respJSON.pivc_id,
          ...(respJSON.body?.pivc_result ?? {}),
          wellness_score: Math.round(respJSON.body?.wellness_score ?? 0),
        };
      else return undefined;
    else throw new Error(respJSON.message);
  }, [getToken]);

  useEffect(() => {
    if (state?.pivcID?.length > 0) {
      if (state.result)
        setResult({
          pivc_id: state.pivcID,
          ...(state.result?.pivc_result ?? {}),
          wellness_score: Math.round(state.result?.wellness_score ?? 0),
        });
    } else
      fetchResult()
        .then(setResult)
        .catch(console.error)
        .finally(() => setLoading(false));
  }, [fetchResult, state]);

  return (
    <div className="px-6 pt-2 pb-8">
      <Link to="/" className="flex items-center text-lg font-semibold">
        <img src={Backarrow} alt="" className="h-5 mr-3" /> Result
      </Link>
      {result?.pivc_id?.length > 0 ? (
        <>
          <h2 className="text-2xl font-bold text-white mt-7">
            Digital Health
            <br />
            Assessment Score
          </h2>
          <div className="self-center flex items-center">
            <GlowingHeart className="shrink-0 h-36 w-36" />
            <p className="text-6xl font-bold text-white ml-6">
              {result?.wellness_score || "--"}
            </p>
          </div>
          <p className="mt-3 text-xxs text-justify font-light">
            Digital Health Risk Assessment Score is a numerical measurement of
            an individual's overall state of well-being, taking into account
            various aspects of physical, mental, and emotional health. A "good"
            wellbeing score typically refers to a score that indicates a high
            level of well-being, with low levels of stress, anxiety, and
            negative emotions, and high levels of physical and mental energy,
            resilience, and satisfaction with life.
          </p>
          <div className="mt-6 px-4 py-6 grid grid-cols-2 gap-5 rounded-xl border border-secondary border-dashed">
            {[
              {
                label: "Face Matching",
                value: "Matched",
                status: result?.face_matching,
              },
              {
                label: "Liveness Detection",
                value: "Detected",
                status: result?.liveness_detection,
              },
              {
                label: "Pulse Detection",
                value: "Detected",
                status: result?.pulse_detection,
              },
              {
                label: "Consent Text Matching",
                value: "Matched",
                status: result?.consent_text_matching,
              },
              {
                label: "Verification Status",
                value: "Verified",
                status: result?.verification_status,
              },
            ].map((item) => (
              <div>
                <p className="text-sm text-white">{item.label}</p>
                <div className="mt-1.5 flex items-center space-x-2">
                  <p className="text-base text-secondary font-bold">
                    {`${item.status ? "" : "Not "}${item.value}`}
                  </p>
                  {item.status ? (
                    <Check className="shrink-0 h-4 w-4 p-1 rounded-full bg-secondary text-white" />
                  ) : (
                    <Close className="shrink-0 h-4 w-4 p-0.5 rounded-full bg-secondary text-white" />
                  )}
                </div>
              </div>
            ))}
          </div>
          <a
            className="block mt-8 rounded-full outline-none px-5 py-2.5 bg-gradient text-white text-center"
            href={result?.report_pdf}
            target="_blank"
            rel="noreferrer"
            download={`CarePlix_PIVC_Report_${result?.pivc_id}`}
          >
            Download Report
          </a>
        </>
      ) : (
        <>
          <div className="my-24 text-center">
            <NoResult className="w-20 mx-auto" />
            <h2 className="mt-8 text-sm font-medium">
              Your results are not ready yet.
            </h2>
            <p className="mt-2 text-xxs font-light">
              Please check again later.
            </p>
          </div>
          <button
            disabled={loading}
            className="w-full rounded-full outline-none px-5 py-2.5 bg-gradient disabled:bg-none disabled:bg-[#9EAAC7] text-white"
            type="button"
            onClick={() =>
              fetchResult()
                .then(setResult)
                .catch(console.error)
                .finally(() => setLoading(false))
            }
          >
            Check Now
          </button>
        </>
      )}
      <Link
        disabled={loading}
        className="block mt-6 rounded-full outline-none px-5 py-2.5 bg-gradient text-white text-center"
        to="/pivc/upload-documents"
        replace
      >
        Re-start PIVC Journey
      </Link>
    </div>
  );
};

export default ScanCompleted;
