import { createBrowserRouter, Outlet, RouterProvider } from "react-router-dom";
import AuthProvider from "./utils/auth";
import Header from "./components/Header";
import RotateWarn from "./components/RotateWarn";
import NotFound from "./screens/NotFound";
import Login from "./screens/Login";
import TermsOfUse from "./screens/TermsOfUse";
import PrivacyPolicy from "./screens/PrivacyPolicy";
import BasicDetails from "./screens/BasicDetails";
import MyAccount from "./screens/MyAccount";
import Tutorial from "./screens/Tutorial";
import Support from "./screens/Support";
import Dashboard from "./screens/Dashboard";
import StartScan from "./screens/StartScan";
import SelectScanOption from "./screens/SelectScanOption";
import ScanInstructions from "./screens/ScanInstructions";
import FaceScan from "./screens/FaceScan";
import FingerScan from "./screens/FingerScan";
import CameraPermissionGuide from "./screens/CameraPermissionGuide";
import ScanResult from "./screens/ScanResult";
import IssuanceDetails from "./screens/PIVC/IssuanceDetails";
import UploadDocuments from "./screens/PIVC/UploadDocuments";
import IntermediaryStatus from "./screens/PIVC/IntermediaryStatus";
import VideoConsent from "./screens/PIVC/VideoConsent";
import VideoConsentRecord from "./screens/PIVC/VideoConsentRecord";
import PIVCResult from "./screens/PIVC/Result";
import WatchVideoConsent from "./screens/PIVC/WatchVideoConsent";

const router = createBrowserRouter([
  {
    path: "/",
    element: (
      <AuthProvider>
        <Outlet /> <RotateWarn />
      </AuthProvider>
    ),
    children: [
      {
        path: "",
        element: (
          <main className="min-h-screen relative bg-primary text-white">
            <Header /> <Outlet />
          </main>
        ),
        children: [
          { index: true, element: <Dashboard /> },
          { path: "my-account", element: <MyAccount /> },
          { path: "tutorial", element: <Tutorial /> },
          { path: "support", element: <Support /> },
          { path: "start-scan", element: <StartScan /> },
          { path: "select-scan", element: <SelectScanOption /> },
          { path: "scan-instructions", element: <ScanInstructions /> },
          {
            path: "camera-permission-guide",
            element: <CameraPermissionGuide />,
          },
          { path: "scan-result", element: <ScanResult /> },
          {
            path: "pivc",
            element: <Outlet />,
            children: [
              { index: true, element: <NotFound style={{ height: "auto" }} /> },
              { path: "issuance-details", element: <IssuanceDetails /> },
              { path: "upload-documents", element: <UploadDocuments /> },
              { path: "status", element: <IntermediaryStatus /> },
              { path: "video-consent", element: <VideoConsent /> },
              { path: "video-consent-record", element: <VideoConsentRecord /> },
              { path: "result", element: <PIVCResult /> },
            ],
            errorElement: <NotFound style={{ height: "auto" }} />,
          },
        ],
        errorElement: <NotFound style={{ height: "auto" }} />,
      },
      { path: "login", element: <Login /> },
      { path: "basic-details", element: <BasicDetails /> },
      { path: "face-scan", element: <FaceScan /> },
      { path: "finger-scan", element: <FingerScan /> },
    ],
    errorElement: <NotFound />,
  },
  { path: "pivc/watch-video-consent", element: <WatchVideoConsent /> },
  { path: "terms-of-use", element: <TermsOfUse /> },
  { path: "privacy-policy", element: <PrivacyPolicy /> },
  { path: "*", element: <NotFound /> },
]);

const App = () => <RouterProvider router={router} />;

export default App;
