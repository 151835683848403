import { Fragment, useMemo, useState } from "react";
import { Link, Navigate, useLocation } from "react-router-dom";
import { Dialog, Transition } from "@headlessui/react";
import { useAuth } from "../utils/auth";
import { ReactComponent as Menu } from "../assets/icons/Menu.svg";
import { ReactComponent as Close } from "../assets/icons/Close.svg";
import { ReactComponent as DashboardIcon } from "../assets/icons/Dashboard.svg";
import { ReactComponent as MyAccountIcon } from "../assets/icons/MyAccount.svg";
import { ReactComponent as VerifyIcon } from "../assets/icons/contract.svg";
import { ReactComponent as TutorialIcon } from "../assets/icons/Tutorial.svg";
import { ReactComponent as SupportIcon } from "../assets/icons/Support.svg";
import { ReactComponent as LogoutIcon } from "../assets/icons/Logout.svg";

const Header = () => {
  const { pathname } = useLocation();
  const {
    logout,
    userData: { fname },
  } = useAuth();
  const [isOpen, setIsOpen] = useState(false);
  const greetingsText = useMemo(() => {
    const hourNow = new Date().getHours();
    return hourNow < 12
      ? "Good Morning"
      : hourNow < 17
      ? "Good Afternoon"
      : "Good Evening";
  }, []);

  if (!(fname?.length > 0)) return <Navigate to="/basic-details" replace />;

  return (
    <header className="flex items-center justify-between p-6">
      <div className="w-40">
        <h3 className="text-sm">{greetingsText}</h3>
        <h2 className="text-lg font-medium truncate">{fname}</h2>
      </div>
      <button
        className="shrink-0 h-6 w-6"
        type="button"
        onClick={() => setIsOpen(true)}
      >
        <Menu className="w-full h-full" />
      </button>

      <Transition show={isOpen} as={Fragment}>
        <Dialog as={Fragment} onClose={() => setIsOpen(false)}>
          <Transition.Child
            className="fixed top-0 bottom-0 left-0 right-0 bg-black/70"
            enter="duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="duration-300 delay-150"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Transition.Child
              as={Fragment}
              enter="delay-150 duration-200 transition-transform transform"
              enterFrom="-translate-x-full"
              enterTo="translate-x-0"
              leave="duration-200 transition-transform transform"
              leaveFrom="translate-x-0"
              leaveTo="-translate-x-full"
            >
              <Dialog.Panel
                as="aside"
                className="min-h-full w-64 flex flex-col items-stretch bg-primary text-white"
              >
                <div className="flex items-start justify-between p-6">
                  <div className="w-40">
                    <Dialog.Title as="h3" className="text-sm">
                      {greetingsText}
                    </Dialog.Title>
                    <Dialog.Title
                      as="h2"
                      className="text-lg font-medium truncate"
                    >
                      {fname}
                    </Dialog.Title>
                  </div>
                  <button
                    className="shrink-0 bg-ternary p-1.5 rounded-full"
                    type="button"
                    onClick={() => setIsOpen(false)}
                  >
                    <Close className="h-5 w-5 text-white" />
                  </button>
                </div>
                <nav className="mt-4">
                  <ul className="space-y-4">
                    {[
                      {
                        to: "/",
                        label: "Dashboard",
                        icon: <DashboardIcon className="h-5 w-5" />,
                      },
                      {
                        to: "/my-account",
                        label: "My Account",
                        icon: <MyAccountIcon className="h-5 w-5" />,
                      },
                      {
                        to: "/pivc/issuance-details",
                        label: "Pre-Issuance Verification",
                        icon: <VerifyIcon className="h-5 w-5" />,
                      },
                      {
                        to: "/tutorial",
                        label: "Tutorial",
                        icon: <TutorialIcon className="h-5 w-5" />,
                      },
                      {
                        to: "/support",
                        label: "Support",
                        icon: <SupportIcon className="h-5 w-5" />,
                      },
                    ].map((linkObj) => (
                      <li key={linkObj.to} className="pr-6">
                        <Link
                          to={linkObj.to}
                          className={`flex items-center space-x-2 py-2 px-6 rounded-r-full ${
                            pathname === linkObj.to
                              ? "bg-secondary"
                              : "bg-transparent hover:bg-secondary/50"
                          }`}
                          onClick={() => setIsOpen(false)}
                        >
                          {linkObj.icon}
                          <span className="text-xs font-medium">
                            {linkObj.label}
                          </span>
                        </Link>
                      </li>
                    ))}
                    <li className="pr-6">
                      <button
                        type="button"
                        className="w-full flex items-center space-x-4 py-2 px-6 rounded-r-full outline-none text-error"
                        onClick={() => {
                          setIsOpen(false);
                          logout();
                        }}
                      >
                        <LogoutIcon className="h-5 w-5" />
                        <span className="text-xs font-medium">Logout</span>
                      </button>
                    </li>
                  </ul>
                </nav>
              </Dialog.Panel>
            </Transition.Child>
          </Transition.Child>
        </Dialog>
      </Transition>
    </header>
  );
};

export default Header;
