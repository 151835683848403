import React from "react";
import { Link } from "react-router-dom";
import { ReactComponent as NotFoundLogo } from "../assets/icons/404.svg";

const NotFound = ({ style = {} }) => (
  <div
    className="px-8 pt-2 pb-6 h-screen flex flex-col items-center justify-center"
    style={style}
  >
    <NotFoundLogo className="h-80" />
    <p className="mt-3 text-sm text-white">Page not found</p>
    <Link
      to="/"
      replace
      className="self-stretch mt-12 rounded-full px-5 py-2.5 bg-gradient text-white text-center"
    >
      Back to Dashboard
    </Link>
  </div>
);

export default NotFound;
