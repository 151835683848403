import { useEffect, useMemo, useState } from "react";
import { Link, Navigate, useLocation } from "react-router-dom";
import { Transition } from "@headlessui/react";

import FaceScan from "../assets/images/FaceScan.webp";
import FingerScan from "../assets/images/FingerScan.webp";
import InstructionFaceScan1 from "../assets/images/instructions/face1.webp";
import InstructionFaceScan2 from "../assets/images/instructions/face2.webp";
import InstructionFaceScan3 from "../assets/images/instructions/face3.webp";
import InstructionFaceFingerScan from "../assets/images/instructions/face-finger.webp";
import InstructionFingerScan1 from "../assets/images/instructions/finger1.webp";
import InstructionFingerScan2 from "../assets/images/instructions/finger2.webp";

const ScanInstructions = () => {
  const location = useLocation();
  const scanType = location.state?.scanType;

  const [currentStep, setCurrentStep] = useState(0);
  const instructions = useMemo(
    () =>
      ({
        face: [
          {
            img: InstructionFaceScan1,
            desc: "The ambient light should be bright, consistent, and white.",
          },
          {
            img: InstructionFaceScan2,
            desc: "Remove any face-obstructing items, such as your mask, eyewear, or clothing.",
          },
          {
            img: InstructionFaceScan3,
            desc: "Keep your face and phone steady to avoid movements during the scan.",
          },
          {
            img: InstructionFaceFingerScan,
            desc: "If prompted, allow the camera permission.",
          },
        ],
        finger: [
          {
            img: InstructionFingerScan1,
            desc: "Place your finger on the rear primary camera and the flashlight.",
          },
          {
            img: InstructionFingerScan2,
            desc: "If the flash is not turned on then place some light behind the phone.",
          },
          {
            img: InstructionFaceFingerScan,
            desc: "If prompted, allow the camera permission.",
          },
        ],
      }[scanType] ?? []),
    [scanType]
  );

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentStep((s) => (s + 1) % instructions.length);
    }, 3000);
    return () => {
      clearInterval(timer);
    };
  }, [instructions.length]);

  return scanType === "face" || scanType === "finger" ? (
    <section className="px-8 pt-2 pb-6">
      <div className="flex items-center justify-between space-x-4">
        <h2 className="text-2xl font-semibold">
          How to do a<br />
          <span className="text-secondary capitalize">{scanType} Scan</span>?
        </h2>
        <img
          className="shrink-0 h-28 rounded-full border-4 border-dotted border-white"
          src={scanType === "face" ? FaceScan : FingerScan}
          alt="Instruction"
        />
      </div>

      <div className="relative mt-8 flex flex-nowrap">
        {instructions.map((ins, idx) => (
          <Transition
            key={`instruction-${idx + 1}`}
            show={currentStep === idx}
            enter="transform transition ease-in-out duration-1000"
            enterFrom="translate-x-96 opacity-0"
            enterTo="translate-x-0 opacity-100"
            leave="transform transition ease-in-out duration-1000 "
            leaveFrom="translate-x-0 opacity-100"
            leaveTo="-translate-x-96 opacity-0"
            className="w-0 overflow-visible"
          >
            <div style={{ width: `calc(100vw - 4rem)` }}>
              <img
                className="h-48 w-full object-fill rounded-2xl border-2 border-secondary"
                src={ins.img}
                alt={`Instruction Banner ${idx + 1}`}
              />
              <p className="mt-12 text-center leading-snug">{ins.desc}</p>
            </div>
          </Transition>
        ))}
        <div className="absolute top-[13.5rem] left-0 right-0 flex items-center justify-center space-x-2">
          {instructions.map((_, idx) => (
            <div
              key={`instruction-bubble-${idx + 1}`}
              className={`h-2 w-2 rounded-full ${
                currentStep === idx ? "bg-secondary" : "bg-white"
              }`}
            />
          ))}
        </div>
      </div>

      <Link
        className="block mt-6 w-full rounded-full outline-none px-5 py-2.5 bg-gradient text-white text-center"
        to={`/${scanType}-scan`}
        state={Boolean(location.state?.pivc) ? location.state : undefined}
      >
        Understood, Let's Start
      </Link>
      <Link
        to={-1}
        className="block max-w-max mx-auto mt-4 text-sm font-medium"
      >
        Back
      </Link>

      <div className="mt-6 p-4 rounded-xl bg-gradient-transparent text-white space-y-1.5">
        <h3 className="text-xs font-medium">Disclaimer</h3>
        <p className="text-xxs text-justify">
          For Investigational Use Only. CarePlix is not a substitute for the
          clinical judgment of a healthcare professional. CarePlix is intended
          to improve your awareness of general wellness. CarePlix does not
          diagnose, treat, mitigate or prevent any disease, symptom, disorder or
          abnormal physical state. Consult with a healthcare professional or
          emergency services if you believe you may have a medical issue.
        </p>
      </div>
    </section>
  ) : (
    <Navigate to="/select-option" replace />
  );
};

export default ScanInstructions;
