import { Fragment, useMemo } from "react";
import { Link } from "react-router-dom";
import IosGuide1 from "../assets/images/guides/ios/1.webp";
import IosGuide2 from "../assets/images/guides/ios/2.webp";
import IosGuide3 from "../assets/images/guides/ios/3.webp";
import AndroidGuide1 from "../assets/images/guides/android/1.webp";
import AndroidGuide2 from "../assets/images/guides/android/2.webp";
import AndroidGuide3 from "../assets/images/guides/android/3.webp";

const CameraPermissionGuide = () => {
  const guides = useMemo(() => {
    const isIOS = /iPhone|iPad|iPod|Macintosh|Mac/i.test(navigator.userAgent);
    const browser = isIOS ? "Safari" : "Chrome";
    const list = isIOS
      ? [
          { ins: "Tap on Website Settings as shown below", img: IosGuide1 },
          { ins: "Tap on Camera option", img: IosGuide2 },
          { ins: "Tap on Allow option", img: IosGuide3 },
        ]
      : [
          {
            ins: "Tap on Website Settings icon as shown below",
            img: AndroidGuide1,
          },
          { ins: "Tap on Permissions option", img: AndroidGuide2 },
          {
            ins: "Toggle on the Camera permission as shown below",
            img: AndroidGuide3,
          },
        ];
    return { browser, list };
  }, []);

  return (
    <section className="px-8 pt-2 pb-36">
      <h3 className="text-center text-xl font-bold">
        How to give
        <br />
        camera permission?
      </h3>
      <p className="mt-8 font-light">
        How to give camera access permission from&nbsp;
        <span className="font-medium">{guides.browser}</span>
        &nbsp;site settings:
      </p>

      {guides.list.map((guide, index) => (
        <Fragment key={`guide-step-${index + 1}`}>
          <h6 className="mt-6 font-semibold">Step {index + 1}:</h6>
          <p className="mt-1.5 text-sm font-light">{guide.ins}</p>
          <img
            className="w-5/6 mt-4 mx-auto rounded-t-2xl border border-white/50 pointer-events-none"
            src={guide.img}
            alt={`guide-step-${index + 1}-illustration`}
          />
        </Fragment>
      ))}

      <div className="fixed bottom-0 left-0 right-0 px-8 pb-12 pt-24 bg-gradient-to-t from-[#5716A9]/80 via-[#9300FF]/35 to-transparent">
        <Link
          className="block w-full rounded-full outline-none px-5 py-2.5 bg-gradient text-white text-center"
          to={-3}
        >
          Back
        </Link>
      </div>
    </section>
  );
};

export default CameraPermissionGuide;
