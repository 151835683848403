import { Link, useLocation } from "react-router-dom";

const Status = () => {
  const { state } = useLocation();
  return (
    <section className="px-8 py-16 text-center">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className="h-48 w-48 mx-auto"
        viewBox="0 0 24 24"
      >
        <circle
          style={{
            animation: "success-circle 0.8s ease-in-out 1.2s forwards",
          }}
          opacity="0"
          fill="none"
          stroke="url(#svg-gradient)"
          strokeDasharray="75 75"
          cx="12"
          cy="12"
          r="11.5"
        />
        <polyline
          style={{ animation: "success-tick 0.9s ease 2.2s forwards" }}
          opacity="0"
          fill="none"
          strokeDasharray="15 15"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          stroke="url(#svg-gradient)"
          points="17,8.5 9.5,15.5 7,13"
        />
        <defs>
          <linearGradient
            id="svg-gradient"
            x1="50%"
            y1="0%"
            x2="50%"
            y2="100%"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#6013AF" />
            <stop offset="100%" stopColor="#1037C2" />
          </linearGradient>
        </defs>
      </svg>
      <h1 className="mt-8 text-ternary text-xl font-semibold">
        {state?.title}
      </h1>
      <p className="mt-6 font-light">{state?.subtitile}</p>
      <Link
        replace
        {...(state?.next ?? {})}
        className="block mt-12 rounded-full outline-none px-5 py-2.5 bg-gradient text-white text-center"
      >
        Proceed
      </Link>
    </section>
  );
};

export default Status;
