import React, { useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Listbox, Transition } from "@headlessui/react";
import { useAuth } from "../../utils/auth";
import { sdkWrapperURL } from "../../utils/api-url-list";
import { ReactComponent as Chevron } from "../../assets/icons/Chevron.svg";
import { ReactComponent as Upload } from "../../assets/icons/Upload.svg";
import { ReactComponent as Close } from "../../assets/icons/Close.svg";
import DocAnalyzing from "../../assets/images/DocAnalyzinf.png";

const IDTypes = [
  {
    id: "aadhaar",
    name: "Aadhaar Card",
    requires: ["front", "back"],
  },
  {
    id: "pan_card",
    name: "PAN Card",
    requires: ["front"],
  },
  {
    id: "driving_licence",
    name: "Driving Licence",
    requires: ["front", "back"],
  },
  {
    id: "passport",
    name: "Passport",
    requires: ["front", "back"],
  },
  {
    id: "voter_card",
    name: "Voter Card",
    requires: ["front", "back"],
  },
];

const getFileExtension = (filename = "") =>
  /(?:\.([^.]+))?$/.exec(filename)?.[1];

const UploadDocuments = () => {
  const { getToken } = useAuth();
  const navigate = useNavigate();
  const [showInstructions, setShowInstructions] = useState(true);
  const [selectedIDType, setSelectedIDType] = useState(0);
  const [picture, setPicture] = useState({});
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const formRef = useRef(null);
  const inputRef = useRef({});

  const selectPicture = (type = "front", file) => {
    if (file) {
      const reader = new FileReader();
      reader.onloadend = (e) =>
        setPicture((p) => ({ ...p, [type]: e.target.result }));
      reader.readAsDataURL(file);
    } else {
      setPicture((p) => ({ ...p, [type]: undefined }));
      if (inputRef.current[type]) {
        inputRef.current[type].value = null;
      }
    }
  };

  const submit = async (e) => {
    e.preventDefault();
    setError("");
    setLoading(true);
    try {
      if (showInstructions) setShowInstructions(false);
      else {
        const selectedID = IDTypes[selectedIDType];
        if (selectedID.requires.some((type) => !picture[type]))
          throw new Error(
            `Please select ${
              selectedID.requires.length > 1 ? "all images" : "the image"
            }.`
          );
        const frontFile = e.target?.front?.files?.[0];
        if (
          selectedID.requires.includes("front") &&
          (!frontFile || frontFile.size > 10485760)
        )
          throw new Error("Please re-select the Front image. (<10MB)");
        const backFile = e.target?.back?.files?.[0];
        if (
          selectedID.requires.includes("back") &&
          (!backFile || backFile.size > 10485760)
        )
          throw new Error("Please re-select the Back image. (<10MB)");
        const token = await getToken();
        const urlResp = await fetch(
          sdkWrapperURL("/pivc/v1/create-presigned-url"),
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: token,
            },
            body: JSON.stringify({
              type: "image",
              identity: selectedID.id,
              front_extension: selectedID.requires.includes("front")
                ? getFileExtension(frontFile.name)
                : undefined,
              back_extension: selectedID.requires.includes("back")
                ? getFileExtension(backFile.name)
                : undefined,
            }),
          }
        );
        const urlRespJSON = await urlResp.json();
        if (urlRespJSON?.statusCode?.toString().startsWith("2")) {
          if (selectedID.requires.includes("front")) {
            const frontFileFormdata = new FormData();
            Object.entries(urlRespJSON.body?.front_uri?.fields ?? {}).map(
              ([key, value]) => frontFileFormdata.append(key, value)
            );
            frontFileFormdata.append("file", frontFile);
            const frontResp = await fetch(urlRespJSON.body.front_uri?.url, {
              method: "POST",
              body: frontFileFormdata,
            });
            if (!frontResp.ok)
              throw new Error("Upload Failed for Front Image.");
          }
          if (selectedID.requires.includes("back")) {
            const backFileFormdata = new FormData();
            Object.entries(urlRespJSON.body?.back_uri?.fields ?? {}).map(
              ([key, value]) => backFileFormdata.append(key, value)
            );
            backFileFormdata.append("file", backFile);
            const backResp = await fetch(urlRespJSON.body.back_uri?.url, {
              method: "POST",
              body: backFileFormdata,
            });
            if (!backResp.ok) throw new Error("Upload Failed for Back Image.");
          }
          const response = await fetch(
            sdkWrapperURL("/pivc/v1/image-text-validator"),
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
                Authorization: token,
              },
              body: JSON.stringify({
                front_image: urlRespJSON.body.front_uri?.fields?.key,
                back_image: urlRespJSON.body.back_uri?.fields?.key,
                identity: selectedID.id,
              }),
            }
          );
          const respJSON = await response.json();
          if (respJSON?.statusCode?.toString().startsWith("2")) {
            if (!respJSON?.message?.front_image_status)
              throw new Error(
                `Unable to verify front image of your ${selectedID.name}.\nNote: A clear image with proper orientation is suitable for verification process.`
              );

            if (
              selectedID.id === "pan_card"
                ? respJSON?.message?.back_image_status
                : !respJSON?.message?.back_image_status
            )
              throw new Error(
                `Unable to verify back image of your ${selectedID.name}.\nNote: A clear image with proper orientation is suitable for verification process.`
              );
            navigate("/pivc/status", {
              replace: true,
              state: {
                title: "Upload Successful",
                subtitile: "Proceed to the next step of verification",
                next: {
                  to: "/start-scan",
                  state: { pivc: true, pivcID: urlRespJSON.body.pivc_id },
                },
              },
            });
          } else throw new Error(respJSON.message);
        } else throw new Error(urlRespJSON.message);
      }
    } catch (err) {
      console.error(err);
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  const reset = (e) => {
    setError("");
    setPicture({});
  };

  return (
    <section className="px-6 pt-2 pb-8">
      <h3 className="text-xl font-bold text-white">
        Pre-Issuance
        <br />
        Verification
      </h3>
      <h4 className="my-8 text-xl font-semibold text-white">
        {showInstructions ? "Verification Steps" : "Step 1: Upload any Govt ID"}
      </h4>
      <form ref={formRef} onSubmit={submit} onReset={reset}>
        {showInstructions ? (
          <ul className="list-disc ml-6 mb-24 text-sm font-medium text-white space-y-6">
            <li>Upload any govt photo ID</li>
            <li>Do a 30 seconds face scan</li>
            <li>Record your consent video</li>
          </ul>
        ) : (
          <>
            <Listbox
              value={selectedIDType}
              disabled={loading}
              onChange={(t) =>
                setSelectedIDType((c) => {
                  if (c !== t) formRef.current.reset();
                  return t;
                })
              }
            >
              <Listbox.Button className="w-full flex items-center justify-between px-4 py-3 outline-none rounded-xl border border-ternary bg-white disabled:bg-none disabled:bg-[#9EAAC7]">
                <span className="text-primary text-xs">
                  {IDTypes[selectedIDType].name}
                </span>
                <Chevron className="shrink-0 h-4 w-4 text-ternary" />
              </Listbox.Button>
              <Transition
                className="relative z-50"
                enter="transition duration-150 ease-out"
                enterFrom="transform scale-95 opacity-0"
                enterTo="transform scale-100 opacity-100"
                leave="transition duration-150 ease-out"
                leaveFrom="transform scale-100 opacity-100"
                leaveTo="transform scale-95 opacity-0"
              >
                <Listbox.Options className="absolute top-1 left-0 right-0 py-2 overflow-hidden rounded-xl shadow-md bg-white">
                  {IDTypes.map((idType, index) => (
                    <Listbox.Option
                      className={({ selected }) =>
                        `px-4 py-2 text-xs ${
                          selected ? "bg-ternary text-white" : "text-primary"
                        }`
                      }
                      key={idType.id}
                      value={index}
                    >
                      {idType.name}
                    </Listbox.Option>
                  ))}
                </Listbox.Options>
              </Transition>
            </Listbox>
            {IDTypes[selectedIDType].requires.map((type) => (
              <div key={type} className="mt-6 space-y-3">
                <label htmlFor={type} className="text-sm font-bold uppercase">
                  {type}
                </label>
                <input
                  ref={(e) => (inputRef.current[type] = e)}
                  className="sr-only"
                  type="file"
                  id={type}
                  name={type}
                  accept=".jpg, .jpeg, .png"
                  onChange={(e) => selectPicture(type, e.target?.files?.[0])}
                  disabled={loading}
                />
                {picture[type] ? (
                  <div className="h-40 max-w-max relative">
                    <img
                      className="h-full object-fill rounded-xl"
                      src={picture[type]}
                      alt={type}
                    />
                    <button
                      className="absolute -top-3 -right-3 h-6 w-6 p-0.5 rounded-full bg-error text-white"
                      type="button"
                      onClick={() => selectPicture(type)}
                      disabled={loading}
                    >
                      <span className="sr-only">Clear</span>
                      <Close className="h-full w-full" />
                    </button>
                  </div>
                ) : (
                  <label
                    htmlFor={type}
                    className="h-40 p-4 flex flex-col justify-evenly items-center text-center rounded-xl bg-gradient-to-b from-[#680FAD] to-[#063BC4]"
                  >
                    <Upload className="shrink-0 h-16 w-16" />
                    <div>
                      <p className="text-xs">Upload Documents</p>
                      <p className="opacity-70 text-xxs font-light">
                        (Only .jpg/.jpeg/.png files less than 10MB are accepted)
                      </p>
                    </div>
                  </label>
                )}
              </div>
            ))}

            {loading && (
              <div className="fixed top-0 bottom-0 left-0 right-0 px-8 py-12 flex flex-col items-center justify-center bg-primary/95 text-white text-center">
                <img
                  className="shrink-0 h-48"
                  src={DocAnalyzing}
                  alt="Analyzing..."
                />
                <h4 className="mt-6 text-lg font-semibold text-ternary">
                  Analyzing Document
                </h4>
                <p className="mt-3 font-light text-base">
                  This will take some time...
                </p>
              </div>
            )}

            {error.length > 0 && (
              <p className="mt-8 -mb-5 mx-1.5 text-error text-xxs text-center whitespace-pre-line">
                {error}
              </p>
            )}
          </>
        )}
        <button
          type="submit"
          disabled={
            loading ||
            (showInstructions === false &&
              IDTypes[selectedIDType].requires.length !==
                Object.keys(picture).length)
          }
          className="block mt-8 w-full rounded-full outline-none px-5 py-2.5 bg-gradient disabled:bg-none disabled:bg-[#9EAAC7] text-white"
        >
          {showInstructions ? "Get Started" : "Next"}
        </button>
        <Link
          to={-1}
          className="block max-w-max mx-auto mt-4 text-sm font-medium"
        >
          Cancel
        </Link>
      </form>
    </section>
  );
};

export default UploadDocuments;
