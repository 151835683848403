import { Link, useLocation, useNavigate } from "react-router-dom";
import { ReactComponent as GlowingHeart } from "../../assets/icons/GlowingHeart.svg";
import { useEffect, useMemo, useRef, useState } from "react";
import { useAuth } from "../../utils/auth";
import { sdkWrapperURL } from "../../utils/api-url-list";

const VideoConsentRecord = () => {
  const { getToken } = useAuth();
  const navigate = useNavigate();
  const { state } = useLocation();
  const [permissions, setPermissions] = useState(false);
  const [consentText, setConsentText] = useState("...");
  const [recording, setRecording] = useState(false);
  const [videoURL, setVideoURL] = useState("");
  const [loading, setLoading] = useState(false);
  const mediaRecorder = useRef(null);
  const videoRef = useRef(null);
  const chunks = useRef([]);
  const videoFile = useRef(null);
  const videoExtension = useMemo(
    () =>
      /iPhone|iPad|iPod|Macintosh|Mac/i.test(navigator.userAgent)
        ? "mp4"
        : "webm",
    []
  );

  const setupCamera = () =>
    new Promise(async (resolve, reject) => {
      try {
        const stream = await navigator.mediaDevices.getUserMedia({
          audio: true,
          video: {
            facingMode: "user",
            aspectRatio: { ideal: 4 / 3 },
            frameRate: { ideal: 30 },
            height: { ideal: 480 },
          },
        });
        videoRef.current.srcObject = stream;
        videoRef.current.onloadedmetadata = async () => {
          await videoRef.current.play?.();
          resolve();
        };
      } catch (err) {
        reject(
          new Error("We are not able to access the Camera. Please try again.", {
            cause: err,
          })
        );
      }
    });

  useEffect(() => {
    setPermissions(false);
    setupCamera()
      .then(async () => {
        const token = await getToken();
        const textResp = await fetch(
          sdkWrapperURL("/pivc/v1/speech-text-generator"),
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: token,
            },
            body: JSON.stringify({}),
          }
        );
        const textRespJSON = await textResp.json();
        if (textRespJSON?.statusCode?.toString().startsWith("2")) {
          setConsentText(textRespJSON.speech_text);
          setPermissions(true);
        } else throw new Error(textRespJSON.message);
      })
      .catch((error) => {
        console.error(error);
        if (error.cause?.name === "NotAllowedError")
          navigate("/camera-permission-guide");
      });
  }, [getToken, navigate]);

  const stopRecording = () => {
    try {
      mediaRecorder.current.stop();
      videoRef.current.srcObject.getTracks().forEach((track) => track.stop());
      setRecording(false);
    } catch (err) {
      console.error(err);
    }
  };

  const startRecording = () => {
    try {
      mediaRecorder.current = new MediaRecorder(videoRef.current.srcObject, {
        videoBitsPerSecond: 1000000,
      });
      mediaRecorder.current.ondataavailable = handleDataAvailable;
      mediaRecorder.current.onstop = handleStop;
      mediaRecorder.current.start();
      setRecording(true);
    } catch (err) {
      console.error(err);
    }
  };

  const handleDataAvailable = (event) => {
    try {
      if (event.data.size > 0) chunks.current.push(event.data);
    } catch (err) {
      console.error(err);
    }
  };

  const handleStop = () => {
    try {
      const blob = new Blob(chunks.current, {
        type: `video/${videoExtension}`,
      });
      chunks.current = [];
      videoFile.current = new File(
        [blob],
        `consent-proof-video.${videoExtension}`,
        {
          type: blob.type,
        }
      );
      const videoURL = URL.createObjectURL(blob);
      setVideoURL(videoURL);
    } catch (err) {
      console.error(err);
    }
  };

  const submit = async () => {
    setLoading(true);
    try {
      const token = await getToken();
      const urlResp = await fetch(
        sdkWrapperURL("/pivc/v1/create-presigned-url"),
        {
          method: "POST",
          headers: { "Content-Type": "application/json", Authorization: token },
          body: JSON.stringify({
            type: "video",
            video_extension: videoExtension,
            pivc_id: state?.pivcID,
          }),
        }
      );
      const urlRespJSON = await urlResp.json();
      if (urlRespJSON?.statusCode?.toString().startsWith("2")) {
        const videoFileFormdata = new FormData();
        Object.entries(urlRespJSON.body?.video_uri?.fields ?? {}).map(
          ([key, value]) => videoFileFormdata.append(key, value)
        );
        videoFileFormdata.append(
          "file",
          videoFile.current,
          `consent-proof-video.${videoExtension}`
        );
        const videoResp = await fetch(urlRespJSON.body.video_uri?.url, {
          method: "POST",
          body: videoFileFormdata,
        });
        if (videoResp.ok)
          navigate("/pivc/status", {
            replace: true,
            state: {
              title: "Congratulations!",
              subtitile:
                "You have successfully completed the verification process.",
              next: { to: "/pivc/result" },
            },
          });
        else throw new Error("Failed to Upload Consent Video");
      } else
        throw new Error(
          urlRespJSON.message ?? "Failed to Upload Consent Video"
        );
    } catch (err) {
      console.error(err);
      alert(err.message);
    } finally {
      setLoading(false);
    }
  };

  const reset = async () => {
    try {
      chunks.current = [];
      videoFile.current = null;
      mediaRecorder.current = null;
      setVideoURL("");
      setRecording(false);
      await setupCamera();
    } catch (err) {
      console.error(err);
    }
  };

  const btnProps = useMemo(() => {
    if (videoURL.length > 0) return { label: "Submit", fn: submit };
    else if (recording) return { label: "Stop Recording", fn: stopRecording };
    else return { label: "Start Recording", fn: startRecording };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [videoURL.length, recording]);

  return (
    <section className="px-6 pt-2 pb-40 relative">
      <GlowingHeart className="absolute -top-7 right-1 h-24 w-24 z-50" />
      <div
        className={`${
          recording ? "visible" : "invisible"
        } inline-flex items-center justify-between space-x-2`}
      >
        <p className="text-white text-2xl font-bold">REC</p>
        <div className="shrink-0 h-5 w-5 rounded-full bg-error animate-pulse" />
      </div>
      <div className="my-8 relative rounded-2xl overflow-hidden">
        <video
          className={`${videoURL.length > 0 ? "" : "hidden "}w-full`}
          src={videoURL}
          playsInline
          controls
          controlsList="nodownload nofullscreen noremoteplayback noplaybackrate"
          disablePictureInPicture
          disableRemotePlayback
        />
        <video
          className={`${
            videoURL.length > 0 ? "hidden " : ""
          }w-full -scale-x-100 bg-[#9EAAC7]`}
          ref={videoRef}
          autoPlay
          muted
          playsInline
        />
      </div>
      {videoURL.length > 0 && (
        <button
          type="button"
          className="mx-auto my-6 flex items-center space-x-2 text-white"
          onClick={reset}
        >
          <svg className="shrink-0 h-8 w-8" viewBox="0 0 34 34" fill="none">
            <path
              d="M25.003 8.99518C22.9489 6.94102 20.1297 5.66602 16.9989 5.66602C10.7372 5.66602 5.67969 10.7377 5.67969 16.9993C5.67969 23.261 10.7372 28.3327 16.9989 28.3327C22.283 28.3327 26.6889 24.7202 27.9497 19.8327H25.003C23.8414 23.1335 20.6964 25.4993 16.9989 25.4993C12.3097 25.4993 8.49885 21.6885 8.49885 16.9993C8.49885 12.3102 12.3097 8.49935 16.9989 8.49935C19.3505 8.49935 21.4472 9.47685 22.9772 11.021L18.4155 15.5827H28.3322V5.66602L25.003 8.99518Z"
              fill="currentColor"
            />
          </svg>
          <span className="text-lg">Start Again</span>
        </button>
      )}
      <p className="text-white text-sm text-justify">{consentText}</p>
      <div className="fixed bottom-0 left-0 right-0 flex flex-col items-center justify-center px-6 pt-8 pb-5 rounded-t-2xl bg-[#051645]/50">
        <button
          type="button"
          onClick={btnProps.fn}
          disabled={!permissions || loading}
          className="w-full rounded-full outline-none px-5 py-2.5 bg-gradient disabled:bg-none disabled:bg-[#9EAAC7] text-white"
        >
          {btnProps.label}
        </button>
        <Link to={-1} className="mt-4 text-sm font-medium">
          Cancel
        </Link>
      </div>
    </section>
  );
};

export default VideoConsentRecord;
